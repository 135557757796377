<template>
    <div class="inline-block" v-if="event">
        <div class="inline-block mx-1 tags_button_wrapper" @click.prevent="addtag(event, 'star')">
            <i class="far fa-star inline-block bg-white rounded-lg border custom-border drop-shadow-sm hover:bg-gray-100 rounded-full tags_button" 
            :class="{
                'bg-custom-500 text-white': doesTagContain(event, 'star'), 
                'bg-white text-gray-800': ! doesTagContain(event, 'star'), 
            }"></i> 
        </div>
        <div class="inline-block mx-1 tags_button_wrapper" @click.prevent="addtag(event, 'exclamation')">
            <i class="fas fa-exclamation inline-block  rounded-lg border custom-border drop-shadow-sm hover:bg-gray-100 rounded-full tags_button" 
            :class="{
                'bg-custom-500 text-white': doesTagContain(event, 'exclamation'), 
                'bg-white text-gray-800': ! doesTagContain(event, 'exclamation'), 
            }"></i> 
        </div>
        <div class="inline-block mx-1 tags_button_wrapper" @click.prevent="addtag(event, 'times')">
            <i class="fas fa-ban inline-block bg-white rounded-lg border custom-border drop-shadow-sm hover:bg-gray-100 rounded-full tags_button" 
            :class="{
                'bg-custom-500 text-white': doesTagContain(event, 'times'), 
                'bg-white text-gray-800': ! doesTagContain(event, 'times'), 
            }"></i> 
        </div>
        <div class="inline-block mx-1 tags_button_wrapper" @click.prevent="addtag(event, 'bulb')">
            <i class="fas fa-lightbulb inline-block bg-white rounded-lg border custom-border drop-shadow-sm hover:bg-gray-100 rounded-full tags_button" 
            :class="{
                'bg-custom-500 text-white': doesTagContain(event, 'bulb'), 
                'bg-white text-gray-800': ! doesTagContain(event, 'bulb'), 
            }"></i> 
        </div>
        <div class="inline-block mx-1 tags_button_wrapper" @click.prevent="addtag(event, 'question')">
            <i class="fas fa-question inline-block bg-white rounded-lg border custom-border drop-shadow-sm hover:bg-gray-100 rounded-full tags_button" 
            :class="{
                'bg-custom-500 text-white': doesTagContain(event, 'question'), 
                'bg-white text-gray-800': ! doesTagContain(event, 'question'), 
            }"></i> 
        </div>                                                                 
    </div>
</template>

<script>
export default {
    props: {
        event: Object,
        eventIndex: Number,
        parent: String
    },
    data(){
        return {
            loadedEvent: null
        }
    },
    methods: {
        addtag(event, tag){
            if(! event.hasOwnProperty('tags')){
                event.tags = []
            }

            const {tags} = event
            
            if(!tags){
                event.tags = [tag]
            }

            if(tags){
                let doesTagAlreadyExist = tags.findIndex(elem =>{
                return elem == tag
                })
                
                if(doesTagAlreadyExist === -1){
                    event.tags.push(tag)
                }else{
                    event.tags.splice(doesTagAlreadyExist, 1)
                }
            }

            // Save changes to database
            
            let eventEditedObj = {
                event: this.event, 
                index: this.eventIndex
            }
            this.$emit('save-event', eventEditedObj)

        },
        doesTagContain(event, tag){
            if(! event.hasOwnProperty('tags')){
                return false
            } 

            const {tags} = event

            if(!tags){
                return false
            }

            let isfound = tags.find(elem => {
                return elem == tag
            })

            if(isfound){
                return true
            }else{
                return false
            }
        },
    }
}
</script>

<style>

</style>