<template>
  <div class="antialiased bg-body text-body font-body admin-port">    
    <!-- Content Begins -->

    <div class="mx-auto" :class="{'lg:ml-80': !getFullScreenDisplayStatus}">
      <adminUserTopNavVue/> 
      
      <!-- Content Header Begins -->
      <section class="py-8">
        <div class="container px-4 mx-auto">
            <div class="p-6 bg-white lg:flex justify-between rounded shadow">
                <div class="">
                    <p class="text-sm text-gray-500">Changes made on the form are saved locally. As such, those changes have to be uploaded to the server to be saved permanently. Click the buttom to save your changes permanently</p>
                </div>
                <div class="w-full md:w-auto my-6 md:my-0 flex items-center">
                  <div class="relative inline-block">
                      <!-- Dropdown toggle button -->
                      <button @click="isOpen = !isOpen" class="relative z-10 block p-2 text-gray-700 bg-white border border-purple-900 rounded-md focus:border-purple-300 focus:ring-opacity-40 focus:ring-purple-200 focus:ring  focus:outline-none">
                          <svg class="w-5 h-5 text-gray-800 dark:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                          </svg>
                      </button>

                      <!-- Dropdown menu -->
                      <div v-show="isOpen" 
                          @click="isOpen = false"
                          x-transition:enter="transition ease-out duration-100"
                          x-transition:enter-start="opacity-0 scale-90"
                          x-transition:enter-end="opacity-100 scale-100"
                          x-transition:leave="transition ease-in duration-100"
                          x-transition:leave-start="opacity-100 scale-100"
                          x-transition:leave-end="opacity-0 scale-90" 
                          class="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl dark:bg-gray-800"
                      >
                          <span @click.prevent="uploadFormSchema()" href="#" class="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100 hover:cursor-pointer"> Upload Form Changes </span>
                          <router-link :to="{name: 'formConditionals'}" class="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100 hover:cursor-pointer"> Conditions </router-link>
                          <!-- <span href="#" class="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100 hover:cursor-pointer"> Help </span>
                          <span href="#" class="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100 hover:cursor-pointer"> Settings </span>
                          <span href="#" class="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100 hover:cursor-pointer"> Sign Out </span> -->
                      </div>
                  </div>
                </div>

            </div>
        </div>
      </section>
      <!-- Content Header ends -->

      <section class="py-4">
        <div class="container px-4 mx-auto">
          <div class="py-4 bg-white shadow rounded form-wrapper relative">
            <div v-if="isSaving" class="general-overlay"></div>
            <div class="flex flex-wrap items-center mb-3">
              <div class='fb-main'></div>
            </div>          
          </div>
        </div>
      </section>
    </div>

    <!-- Content Ends -->
  </div>
</template>


<script>
import adminUserTopNavVue from '@/components/header/adminUserTopNav.vue'
import { adminFormService } from '@/services'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Form_Editor',
  data(){
    return {
      form_id: null,
      error: null,
      form: null,
      isSaving: false,
      isOpen: false
    }
  },
  components:{
    adminUserTopNavVue,
  },
  methods:{
    checkForFormID(){
      if(this.$route.params.form_id){
        this.form_id = this.$route.params.form_id
      }else{
        this.$router.push({name: 'forms'})
      }
    },
    async getForm(){
      let self = this
      
      await adminFormService.getFormByFormID(self.form_id)
        .then((res)=>{
          if(res.type === 'success'){
            self.form = res.data

          }else if(res.type === 'error'){
            
          }
        })
    },
    async prepareEditor(){
      let self = this
      
      await this.getForm()
      // console.log(self.form.form_schema)
      let schema = self.form.form_schema
          schema = (JSON.parse(schema).fields) ? JSON.parse(schema).fields : []


      if(this.form && schema){
        let fb = new Formbuilder({
          selector: '.fb-main',
          bootstrapData: schema
        });

        fb.on('save', function(payload){
          self.form.form_schema = payload
          console.log(payload)
        })
      }
    },
    async uploadFormSchema(){
      let form = this.form
      if(form){
        this.isSaving = true
        let self = this
      
        await adminFormService.saveFormChanges(self.form)
          .then((res)=>{
            if(res.type === 'success'){
              self.form = res.data
              self.isSaving = false
              
            }else if(res.type === 'error'){
              self.isSaving = false              
            }
          })
          .catch(()=>{
            self.isSaving = false
          })
        
      }
    }
   
  },
  mounted(){
    this.checkForFormID()
    this.prepareEditor()
  },
  computed: {
    ...mapGetters(['getAllDropdownToShow', 'getFullScreenDisplayStatus']),
  }
}
</script>

<style>
/* Animations */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.form-wrapper{
  min-height: 70vh;
}
</style>
