import { authHeader } from '@/helper';
import { URL } from '@/helper';
import axios from 'axios'
import { authServices } from '@/services'


export const adminService = {
    getAllUsers,
    fetchUserByUsername,
    saveUserDetails,
    createNewUser
};

async function getAllUsers() {
    return await axios({
            method: 'post',
            url: URL+'/api/get-all-users',            
            headers: authHeader()
          }            
        )
        .then(function (response) {

            if (response.data.status == "error") { 
                var res = {
                    type: 'error',
                    location: 'userView', 
                    message: response.data.message  
                }  
                return res                          
            }else{
                let users = response.data.data
                if (users) {
                    var res = {
                        type: 'success',
                        location: 'userView', 
                        data: users 
                    }
                    return res
                }
            }
        })
        .catch(async function (err) {
            if(err.response){
                if (err.response.data.message == "Expired token") {
                    await authServices.logout()
                }
            }

            return prepareError(err.response.data.message)
        });
}

async function fetchUserByUsername(username) {
    return await axios({
            method: 'post',
            url: URL+'/api/get-users-by-email',            
            headers: authHeader(),
            data: {
                user_email: username
            }
          }            
        )
        .then(function (response) {

            if (response.data.status == "error") { 
                var res = {
                    type: 'error',
                    location: 'userView', 
                    message: response.data.message  
                }  
                return res                          
            }else{
                let users = response.data.data
                if (users) {
                    var res = {
                        type: 'success',
                        location: 'userView', 
                        data: users 
                    }
                    return res
                }
            }
        })
        .catch(async function (err) {
            if(err.response){
                if (err.response.data.message == "Expired token") {
                    await authServices.logout()
                }
            }

            return prepareError(err.response.data.message)
        });
}
    
async function saveUserDetails(userId, requestBody){
    return await axios({
            method: 'post',
            url: URL+'/api/save-users',            
            headers: authHeader(),
            data: {
                user_id: userId,
                user_details: requestBody
            }
            }            
        )
        .then(function (response) {

            if (response.data.status == "error") { 
                var res = {
                    type: 'error',
                    location: 'userView', 
                    message: response.data.message  
                }  
                return res                          
            }else{
                let users = response.data.data
                if (users) {
                    var res = {
                        type: 'success',
                        location: 'userView', 
                        data: users 
                    }
                    return res
                }
            }
        })
        .catch(async function (err) {
            if(err.response){
                if (err.response.data.message == "Expired token") {
                    await authServices.logout()
                }
            }

            return prepareError(err.response.data.message)
        });       
}
async function createNewUser(requestBody){
    return await axios({
            method: 'post',
            url: URL+'/api/create-user',            
            headers: authHeader(),
            data: {
                    user_details: requestBody
                }
            }            
        )
        .then(function (response) {

            if (response.data.status == "error") { 
                var res = {
                    type: 'error',
                    location: 'userView', 
                    message: response.data.message  
                }  
                return res                          
            }else{
                let users = response.data.data
                if (users) {
                    var res = {
                        type: 'success',
                        location: 'userView', 
                        data: users 
                    }
                    return res
                }
            }
        })
        .catch(async function (err) {
            if(err.response){
                if (err.response.data.message == "Expired token") {
                    await authServices.logout()
                }
            }

            return prepareError(err.response.data.message)
        });       
}

function prepareError(msg){
    var res = {
        type: 'error',
        location: 'UserView', 
        message: null 
    }

    res.message = (msg)
        ? msg
        : 'Sorry, we are experiencing some chanllenges. Please contact support'

    return res
}
