export function authHeader(isFormData) {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.userJWT && isFormData) {
        return { 
            'Authorization': 'Bearer ' + user.userJWT, 
            'Content-Type': 'multipart/form-data'
        };
    }else if(user && user.userJWT){
        return { 
            'Authorization': 'Bearer ' + user.userJWT, 
        };
    } else {
        return {};
    }
}