<template>
    <div>        
        <div class='conditionals-container lg:px-10 px-4 py-2'>                  
            <div class="relative block p-6 lg:w-1/2 md:w-2/3 w-full bg-white rounded-lg border border-gray-200 shadow-sm ">
                <p class="font-normal text-gray-700 conditionMenuInitiator">
                    <span class="font-bold">IF</span> {{currentCondition.if_condition.fieldLabel}} 
                    <span class="font-bold">{{getLabel(currentCondition.state_condition)}}</span> 
                    {{
                        ((currentCondition.state_condition == 'isfilled') || (currentCondition.state_condition == 'isempty')) 
                            ? '...' 
                            : currentCondition.value_condition}}
                    <span class="font-bold">{{currentCondition.do_condition}}</span> 
                    {{currentCondition.on_condition.fieldLabel}}
                </p>
                <div class="absolute top-0 left-0 w-full h-full flex items-center bg-gray-100/30 conditionMenu">
                    <div class="flex w-full mt-4 w-full items-center justify-end">
                        <div @click="editCondition()" class="flex items-center w-10 h-10 p-3 text-gray-700 rounded-full bg-white border border-gray-300 hover:cursor-pointer hover:border-gray-400 ">
                            <i class="fas fa-pen"></i>
                        </div>
                        <div @click="deleteCondition()" class="flex mx-3 items-center w-10 h-10 p-3 text-gray-700 rounded-full bg-white border border-red-300 hover:cursor-pointer hover:border-red-400 ">
                            <i class="fas fa-trash"></i>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>        
    </div>
</template>

<script>
export default {
    name: 'aCondition',
    props: {
        currentCondition: Object,
    },
    data(){
        return {
            possibleStates: [
                { 
                    value: 'isequalto',
                    label: 'Is Equal To',
                },
                { 
                    value: 'isnotequalto',
                    label: 'Is Not Equal To',
                },
                { 
                    value: 'isfilled',
                    label: 'Is Filled',
                },
                { 
                    value: 'isempty',
                    label: 'Is Empty',
                },
            ],
        }
    },
    methods: {
        editCondition(){
            this.$emit('editCondition')
        },   
        deleteCondition(){
            this.$emit('deleteCondition')
        },   
        getLabel(value){
            let state = this.possibleStates.find((elem)=>{
                return elem.value == value
            })            

            return state.label
        }
    },
    mounted(){
    }
}
</script>

<style>

</style>