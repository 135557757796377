import { authHeader } from '@/helper';
import { URL } from '@/helper';
import { authServices } from '@/services'
import axios from 'axios'


export const adminFormService = {
    getAllForms,
    getFormByFormID,
    createNewForm,
    saveFormChanges,
    getFormByName,
    deleteFormByFormID
};

async function getAllForms() {
    return await axios({
            method: 'post',
            url: URL+'/api/forms',            
            headers: authHeader(),
            data: {
                ownerId: authServices.user().id, 
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let forms = response.data.data
                if (forms) {
                    var res = {
                        type: 'success',
                        location: 'formView', 
                        data: forms 
                    }
                    return res
                }
            }
        })
        .catch(function (err) {
            if(err.response){
                if (err.response.data.message === "Expired token") {
                    authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}

async function getFormByFormID(form_id) {
    return await axios({
            method: 'post',
            url: URL+'/api/form',            
            headers: authHeader(),
            data: {
                form_id: form_id
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let form = response.data.data
                if (form) {
                    var res = {
                        type: 'success',
                        location: 'formView', 
                        data: form
                    }
                    return res
                }
            }
        })
        .catch(function (err) {
            if(err.response){
                if (err.response.data.message === "Expired token") {
                    authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}

async function deleteFormByFormID(form_id) {
    return await axios({
            method: 'post',
            url: URL+'/api/delete-form',            
            headers: authHeader(),
            data: {
                form_id: form_id
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let form = response.data.data
                if (form) {
                    var res = {
                        type: 'success',
                        location: 'formView', 
                        data: form
                    }
                    return res
                }
            }
        })
        .catch(function (err) {
            if(err.response){
                if (err.response.data.message === "Expired token") {
                    authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}

async function getFormByName(form_name) {
    return await axios({
            method: 'post',
            url: URL+'/api/prepare-form',            
            data: {
                form_name: form_name
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let form = response.data.data
                if (form) {
                    var res = {
                        type: 'success',
                        location: 'publicView', 
                        data: form
                    }
                    return res
                }
            }
        })
        .catch(function (err) {
            if(err.response){
        
            }
            return prepareError(err.response.data.message)
        });
}

async function createNewForm(formName) {
    return await axios({
            method: 'post',
            url: URL+'/api/create-forms',            
            headers: authHeader(),
            data: {
                ownerId: authServices.user().id, 
                formName: formName
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let events = response.data.data
                if (events) {
                    var res = {
                        type: 'success',
                        location: 'formView', 
                        data: events[0] 
                    }
                    return res
                }
            }
        })
        .catch(function (err) {
            if(err.response){
                if (err.response.data.message === "Expired token") {
                    authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}

async function saveFormChanges(form) {
    return await axios({
            method: 'post',
            url: URL+'/api/save-forms',            
            headers: authHeader(),
            data: {
                form: form
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let form = response.data.data
                if (form) {
                    var res = {
                        type: 'success',
                        location: 'formView', 
                        data: form
                    }
                    return res
                }
            }
        })
        .catch(function (err) {
            if(err.response){
                if (err.response.data.message === "Expired token") {
                    authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}

async function saveFormCondition(condition){
    return await axios({
        method: 'post',
        url: URL+'/api/save-conditions',            
        headers: authHeader(),
        data: {
            condition: condition
        }
      }            
    )
    .then(function (response) {
        if (response.data.status == "error") {        
            return prepareError(response.data.message)                  
        }else{
            let form = response.data.data
            if (form) {
                var res = {
                    type: 'success',
                    location: 'formView', 
                    data: form
                }
                return res
            }
        }
    })
    .catch(function (err) {
        if(err.response){
            if (err.response.data.message === "Expired token") {
                authServices.logout()
            }
        }
        return prepareError(err.response.data.message)
    });    
}

function prepareError(msg){
    var res = {
        type: 'error',
        location: 'formView', 
        message: null 
    }

    res.message = (msg)
        ? msg
        : 'Sorry, we are experiencing some chanllenges. Please contact support'

    return res
}
