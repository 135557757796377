<template>
    <div v-if="getStatus_HeaderOnPublicPages">
        <Main_Nav v-if="getNavType == 'main' "/>
        <Login_Nav v-if="getNavType == 'admin' "/>
    </div>
</template>

<script>
import Login_Nav from "@/components/header/Login_Nav.vue";
import Main_Nav from "@/components/header/Main_Nav.vue";
import { mapGetters } from "vuex";

export default {
    name: 'HeaderComponent',
    components: {
        Login_Nav,
        Main_Nav
    },
    data(){
        return{
            navbarType: 'main'
        }
    },
    computed:{
        ...mapGetters(['getNavType', 'getStatus_HeaderOnPublicPages'])
    }
}
</script>

<style>

</style>