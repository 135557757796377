<template>
  <div class="antialiased bg-body text-body font-body admin-port">
    <!-- Content Begins -->
    <div class="mx-auto lg:ml-80">
      <adminUserTopNav/>

      <div class="relative">

        <!-- Content Header Begins -->
        <section class="py-8">
          <div class="container px-4 mx-auto">
            <div class="p-6 bg-white rounded shadow">
              <div class="md:flex justify-between items-center my-4">
                <div>
                  <h4 class="text-sm text-gray-800 my-2">Access you clients and fellow Administrator</h4>
                </div>
                <button @click.prevent="addNewUser = true" type="button" class="text-white bg-custom-500 hover:bg-custom-800 rounded text-sm py-1.5 px-3 text-center mr-2 my-2">Add New User</button>
              </div>
            </div>
          </div>
        </section>
   
        <div v-if="addNewUser" class="modal-wrapper z-50 w-full flex items-center justify-center"> 
          
          <!-- Main modal -->
          <div class="relative p-4 w-11/12 md:w-1/2">
              <!-- Modal content -->
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <button @click.prevent="addNewUser = false" type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="authentication-modal">
                      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Close modal</span>
                  </button>
                  <div class="py-6 px-6 lg:px-8">
                      <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Enter User Details</h3>
                      <form>
                        <div class="mb-6">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Username/Email</label>
                            <input v-model="user_name" type="email" class="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2" required>
                        </div>
                        <div class="mb-6">
                            <label for="text" class="block mb-2 text-sm font-medium text-gray-900">Name</label>
                            <input v-model="name" type="text" class="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2">
                        </div>
                        <div class="mb-6">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                            <input v-model="user_password" type="password" class="p-2 bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg" @change="password_changed = true">
                        </div>
                        <div class="mb-6">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Confirm password</label>
                            <input v-model="confirm_password" type="password" class="p-2 bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg">
                            <p v-if="formErrors.password_do_not_match" class="mt-2 text-sm text-red-600"><span class="font-medium">Oops!</span> Password do not match</p>
                        </div>
                        <button @click.prevent="createNewUser()" 
                          type="submit" 
                          class="text-white bg-customb-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center inline-block"
                          :disabled="formErrors.password_do_not_match"
                          :class="{'cursor-not-allowed': formErrors.password_do_not_match
                          }"
                        >
                          Save
                        </button>
                        <p v-if="success_createUser" class="mt-2 text-sm text-green-600">User created</p>
                        <p v-if="error_createUser" class="mt-2 text-sm text-red-600">We experienced an error while fulfilling your request. Please contact support</p>
                    </form>
                  </div>
              </div>
          </div>
  
        </div>
  
        
        <!-- Content Header ends -->
        <section class="py-8">
          <div class="container px-4 mx-auto">
            <div class="flex flex-wrap items-stretch -m-4">
              <div class="w-full lg:w-8/12 p-4 ">
                <div class="pt-4 bg-white shadow rounded">
                  <div class="px-6 border-b border-custom-50">
                      <div>
                          <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" >
                              <li class="mr-2" role="presentation">
                                  <button @click="activeTab = 'user'" :class="[(activeTab == 'user') ? activeClasses : '' ]" class="inline-block px-4 pb-2 rounded-t-lg border-b-2 text-sm font-medium" type="button" aria-selected="false">Users</button>
                              </li>
                              <li class="mr-2" role="presentation">
                                  <button @click="activeTab = 'admin'" :class="[(activeTab == 'admin') ? activeClasses : '' ]" class="inline-block px-4 pb-2 rounded-t-lg border-b-2 text-sm font-medium" type="button" aria-selected="false">Admin/Attorney</button>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div class="py-2 bg-gray-50">
                      <usersTab @get-user="getUser" :clients="clients" v-if="activeTab == 'user'"/>
                      <adminTab @get-user="getUser" :admins="admins" v-if="activeTab == 'admin'"/>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-4/12 py-2 px-4 order-first sm:order-last" >
                <div class="bg-white shadow rounded user-info-wrapper flex align-center justify-center">                  
                  <div class="w-full bg-white overflow-y-auto">
                      <div class="flex justify-end px-4 pt-4">
                        <div>
                          <button @click="showUserDropDown = !showUserDropDown" class="inline-block text-gray-500 hover:bg-gray-100 rounded-lg text-sm p-1.5" type="button" :disabled="!selectedUser.fetchedData.user_name && !selectedUser.fetchedData.name">
                              <span class="sr-only">Open dropdown</span>
                              <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path></svg>
                          </button>
                          <div class="relative block">
                            <!-- Dropdown menu -->
                            <div v-if="showUserDropDown && selectedUser.fetchedData.user_name" class="z-10 absolute right-0 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ">
                                <ul class="py-2" aria-labelledby="dropdownButton">
                                <li @click="editSelectedUser()">
                                    <span class="block hover:cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ">Edit</span>
                                </li>
                                <li @click="msgSelectedUser()">
                                    <span class="block hover:cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ">Message</span>
                                </li>
                                <li @click="cancelSelection()">
                                    <span class="block hover:cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ">Cancel Selection</span>
                                </li>
                                <li>
                                    <span class="block hover:cursor-pointer px-4 py-2 text-sm text-red-600 hover:bg-gray-100 ">Delete</span>
                                </li>
                                </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col items-center pb-10">
                          <img class="w-20 h-20 mb-3 rounded-full shadow-lg" src="@/assets/images/user-avatar.jpg"/>
                          <h5 class="mb-1 font-medium text-gray-900 w-5/6 break-all px-3 text-center">
                            {{(selectedUser.fetchedData.user_name) ? selectedUser.fetchedData.user_name : ' '}}
                          </h5>
                          <span v-if="!selectedUser.fetchedData.user_name && !selectedUser.fetchedData.name" class="text-sm text-gray-500">
                            No user selected
                          </span>
                          <span class="text-sm text-gray-500">
                            {{(selectedUser.fetchedData.name) ? selectedUser.fetchedData.name : ' '}}
                          </span>
  
                          <editUserCom v-if="editUser.status"  :data="editUser.data" @reload-users="reloadUsers" />
                          <emailUser v-if="sendMsg.status" :data="sendMsg.data" />
                          
                      </div>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
      </section>
      </div>
                

    </div>
    <!-- Content ends -->
  </div>
</template>

<script>
import adminUserTopNav from '@/components/header/adminUserTopNav.vue'
import usersTab from '@/components/user/users.tab.vue'
import adminTab from '@/components/user/admin.tab.vue'
import { adminService } from '@/services'
import emailUser from '@/components/user/emailUser.vue'
import editUserCom from '@/components/user/editUser.vue'
import { userMixins } from '@/mixins'


export default {
  data(){
    return {
      activeTab: 'user',
      activeClasses: 'text-customb-500 tab-active',
      clients: [],
      admins: [],
      selectedUser: {
        email: null,
        fetchedData: {
          user_name: null,
          name: null
        }
      },
      showUserDropDown: false,
      editUser: {
        status: false,
        data: null
      },
      sendMsg: {
        status: false,
        data: null
      },
      addNewUser: false      
    }
  },
  mixins: [
    userMixins
  ],
  components:{
    adminUserTopNav,
    usersTab,
    adminTab,
    emailUser,
    editUserCom
  },
  methods: {
    async reloadUsers(username){
      await this.getUsers()
      await this.getUser(username)
    },
    editSelectedUser(){
      if(this.sendMsg.status){
        this.sendMsg.status = false
        this.sendMsg.data = null
      }
      this.editUser.status = true
      this.editUser.data = this.selectedUser.fetchedData
      this.showUserDropDown = false
    },
    msgSelectedUser(){
      if(this.editUser.status){
        this.editUser.status = false
        this.editUser.data = null
      }
      
      this.sendMsg.status = true
      this.sendMsg.data = this.selectedUser.fetchedData
      this.showUserDropDown = false
    },
    cancelSelection(){
      this.editUser.status = false
      this.editUser.data = null
      this.sendMsg.status = false

      this.selectedUser.email = null
      this.selectedUser.fetchedData = {
          user_name: null,
          name: null
        }
    },
    async getUser(username){
      console.log(username)
      let self = this

      await adminService.fetchUserByUsername(username)
      .then((res)=>{
        self.selectedUser.fetchedData = res.data
      })
    },
    async getUsers(){
      let self = this
      
      self.selectedUser.email = (self.$route.params.user_email) ? self.$route.params.user_email : null

      adminService.getAllUsers().then((res)=>{
        self.clients = res.data.filter((user)=>{
          return user.user_type === "client"
        })
        self.clients = self.clients.reverse()
        
        self.admins = res.data.filter((user)=>{
          return user.user_type === "admin"
        })
        self.admins = self.admins.reverse()
      })
    
    }
  },
  watch: {
    selectedUser: {
      deep: true,
      handler(newValue, oldValue){
        if(this.editUser.status){
          // destroy first then rebuild
          this.editUser.status = false
          this.editUser.status = true
          this.editUser.data = this.selectedUser.fetchedData
        }
        if(this.sendMsg.status){
          // destroy first then rebuild
          this.sendMsg.status = false
          this.sendMsg.status = true
          this.sendMsg.data = this.selectedUser.fetchedData          
        }
      }
    }
  },
  mounted(){
    this.getUsers()
  }
}
</script>

<style scoped>
.user-info-wrapper{
  height:100%; 
  max-height: 600px;
}
.modal-wrapper{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
}

</style>