<template>
    <div>
      <AdminNav v-if="getUser.user_type === 'admin'"/>
      <UserNav v-if="getUser.user_type === 'client'"/>
    </div>  
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminNav from '@/components/header/navtype/Admin.vue'
import UserNav from '@/components/header/navtype/User.vue'
export default {
  data(){
    return {
      hideMobileNav: true
    }
  },
  components:{
    AdminNav,
    UserNav
  },
  methods:{
    ...mapActions('userModule',[
      'logout'
    ]),
  },
  computed:{
    ...mapGetters('userModule', ['getUser'])
  }
}
</script>

<style>

</style>