<template>
  <div class="antialiased bg-body text-body font-body admin-port">
    <!-- Content Begins -->         
    <div class="mx-auto lg:ml-80 relative full-height">
        <adminUserTopNavVue/>   
        
        <!-- Content Header Begins -->
        <section class="py-8">
            <div class="container px-4 mx-auto">
                <div class="p-6 bg-white rounded shadow">
                    <div class="mb-4">
                        <h3 class="mb-2 font-medium">Theme</h3>
                        <p class="text-sm text-gray-500">Create, edit and update your forms. Forms are to be associated with a timeline. A form can be used to collect client's details in a timeline</p>
                    </div>
                    <div class="w-full md:w-auto my-6 md:my-0 flex items-center">
                        <button type="button" class="text-white bg-custom-500 hover:bg-custom-800 focus:outline-none rounded text-sm py-1.5 px-3 text-center active:outline-none ">Theme Setting</button>
                    </div>
                </div>
            </div>
        </section>
        <!-- Content Header ends -->


        <section class="py-4">
            <div class="container px-4 mx-auto">
              <div class="px-6 pb-6 pt-4 bg-white shadow rounded">
                <div class="flex flex-wrap items-center mb-3">
                  <div>
                  </div>
                  <div class="w-full md:w-auto my-6 md:my-0 flex items-center ml-auto mr-4 pr-4 bg-white border rounded">
                    <input class="pl-2 py-2 text-sm text-gray-200" type="text" placeholder="Type to search...">
                    <button class="ml-2 text-gray-200 hover:text-gray-300">
                      <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.0923 15.9083L14.0007 12.8417C15.2007 11.3453 15.7819 9.44607 15.6247 7.53441C15.4674 5.62274 14.5837 3.84397 13.1553 2.56386C11.7268 1.28374 9.86216 0.599581 7.94476 0.652048C6.02735 0.704515 4.20292 1.48962 2.8466 2.84594C1.49029 4.20226 0.705178 6.02669 0.652711 7.94409C0.600244 9.8615 1.28441 11.7261 2.56452 13.1546C3.84464 14.583 5.6234 15.4668 7.53507 15.624C9.44673 15.7812 11.346 15.2001 12.8423 14L15.909 17.0667C15.9865 17.1448 16.0786 17.2068 16.1802 17.2491C16.2817 17.2914 16.3906 17.3132 16.5007 17.3132C16.6107 17.3132 16.7196 17.2914 16.8211 17.2491C16.9227 17.2068 17.0148 17.1448 17.0923 17.0667C17.2425 16.9113 17.3265 16.7036 17.3265 16.4875C17.3265 16.2714 17.2425 16.0637 17.0923 15.9083ZM8.16732 14C7.0136 14 5.88578 13.6579 4.92649 13.0169C3.96721 12.3759 3.21953 11.4649 2.77802 10.399C2.33651 9.33307 2.22099 8.16019 2.44607 7.02863C2.67115 5.89707 3.22672 4.85767 4.04253 4.04187C4.85834 3.22606 5.89774 2.67049 7.02929 2.44541C8.16085 2.22033 9.33374 2.33585 10.3996 2.77736C11.4655 3.21887 12.3766 3.96654 13.0176 4.92583C13.6585 5.88512 14.0007 7.01293 14.0007 8.16666C14.0007 9.71375 13.3861 11.1975 12.2921 12.2914C11.1981 13.3854 9.71442 14 8.16732 14Z" fill="#4b5563"></path>
                      </svg>
                    </button>
                  </div>
                </div>
                
                <div v-if="! forms.length" class="flex justify-between items-center mb-2 p-4 bg-gray-50 rounded hover:bg-gray-100 transition ease-in-out duration-200">
                  <div class="flex items-center">
                    <div class="text-center">
                      <p class="hidden md:block text-xs text-gray-500">No theme returned</p>
                    </div>
                  </div>
                </div>
                <div v-if="forms.length">                
                  <div
                   v-for="form in displayedForms" 
                   :key="form.form_id" 
                   class="flex justify-between items-center mb-2 p-4 bg-gray-50 rounded hover:bg-gray-100 hover:cursor-pointer transition ease-in-out duration-200">
                    <div class="flex items-center">
                      <span class="inline-flex w-10 h-10 mr-3 justify-center items-center bg-gray-200 rounded">
                        <svg width="16" height="20" viewbox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 12H5C4.73478 12 4.48043 12.1054 4.29289 12.2929C4.10536 12.4804 4 12.7348 4 13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H9C9.26522 14 9.51957 13.8946 9.70711 13.7071C9.89464 13.5196 10 13.2652 10 13C10 12.7348 9.89464 12.4804 9.70711 12.2929C9.51957 12.1054 9.26522 12 9 12ZM13 2H11.82C11.6137 1.41645 11.2319 0.910998 10.7271 0.552938C10.2222 0.194879 9.61894 0.00173951 9 0H7C6.38106 0.00173951 5.7778 0.194879 5.27293 0.552938C4.76807 0.910998 4.38631 1.41645 4.18 2H3C2.20435 2 1.44129 2.31607 0.87868 2.87868C0.316071 3.44129 0 4.20435 0 5V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2ZM6 3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2H9C9.26522 2 9.51957 2.10536 9.70711 2.29289C9.89464 2.48043 10 2.73478 10 3V4H6V3ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V5C2 4.73478 2.10536 4.48043 2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4H4V5C4 5.26522 4.10536 5.51957 4.29289 5.70711C4.48043 5.89464 4.73478 6 5 6H11C11.2652 6 11.5196 5.89464 11.7071 5.70711C11.8946 5.51957 12 5.26522 12 5V4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V17ZM11 8H5C4.73478 8 4.48043 8.10536 4.29289 8.29289C4.10536 8.48043 4 8.73478 4 9C4 9.26522 4.10536 9.51957 4.29289 9.70711C4.48043 9.89464 4.73478 10 5 10H11C11.2652 10 11.5196 9.89464 11.7071 9.70711C11.8946 9.51957 12 9.26522 12 9C12 8.73478 11.8946 8.48043 11.7071 8.29289C11.5196 8.10536 11.2652 8 11 8Z" fill="#4b5563"></path>
                        </svg>
                      </span>
                      <div>
                        <h4 class="text-sm font-medium">{{ form.form_name }}</h4>
                        <p class="hidden md:block text-xs text-gray-500"> <strong>Owner :</strong> {{ form.user_name }}</p>
                      </div>
                    </div>
                    <div class="flex items-center">                    
                      <div class="timelineDropdownElem dropdown-wrapper relative mx-1">
                          <button v-on:click="toggleDropdown(form.form_id)" class="timelineDropdownElem text-white font-bold py-2 px-4 whitespace-no-wrap rounded transition duration-300">
                              <svg class="timelineDropdownElem" width="16" height="4" viewbox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path class="timelineDropdownElem" d="M8 0.333344C7.67037 0.333344 7.34813 0.431092 7.07405 0.614228C6.79997 0.797363 6.58635 1.05766 6.4602 1.36221C6.33406 1.66675 6.30105 2.00186 6.36536 2.32516C6.42967 2.64846 6.5884 2.94543 6.82149 3.17852C7.05458 3.41161 7.35155 3.57034 7.67485 3.63465C7.99815 3.69896 8.33326 3.66596 8.63781 3.53981C8.94235 3.41366 9.20265 3.20004 9.38578 2.92596C9.56892 2.65188 9.66667 2.32965 9.66667 2.00001C9.66667 1.55798 9.49107 1.13406 9.17851 0.821499C8.86595 0.508939 8.44203 0.333344 8 0.333344ZM2.16667 0.333344C1.83703 0.333344 1.5148 0.431092 1.24072 0.614228C0.966635 0.797363 0.753014 1.05766 0.626868 1.36221C0.500722 1.66675 0.467717 2.00186 0.532025 2.32516C0.596334 2.64846 0.755068 2.94543 0.988156 3.17852C1.22124 3.41161 1.51822 3.57034 1.84152 3.63465C2.16482 3.69896 2.49993 3.66596 2.80447 3.53981C3.10902 3.41366 3.36931 3.20004 3.55245 2.92596C3.73559 2.65188 3.83333 2.32965 3.83333 2.00001C3.83333 1.55798 3.65774 1.13406 3.34518 0.821499C3.03262 0.508939 2.6087 0.333344 2.16667 0.333344ZM13.8333 0.333344C13.5037 0.333344 13.1815 0.431092 12.9074 0.614228C12.6333 0.797363 12.4197 1.05766 12.2935 1.36221C12.1674 1.66675 12.1344 2.00186 12.1987 2.32516C12.263 2.64846 12.4217 2.94543 12.6548 3.17852C12.8879 3.41161 13.1849 3.57034 13.5082 3.63465C13.8315 3.69896 14.1666 3.66596 14.4711 3.53981C14.7757 3.41366 15.036 3.20004 15.2191 2.92596C15.4023 2.65188 15.5 2.32965 15.5 2.00001C15.5 1.55798 15.3244 1.13406 15.0118 0.821499C14.6993 0.508939 14.2754 0.333344 13.8333 0.333344Z" fill="#67798E"></path>
                              </svg>
                          </button>
                          <transition name="fade">
                              <div class="z-10 bg-white dropdown-menu  mt-1 rounded absolute z-10 shadow-lg w-40 max-w-xs" v-if="showDropdownKey === form.form_id && getAllDropdownToShow.find(el => {return el == 'timelineDropdownElem'})">
                                  <ul class="list-none overflow-hidden rounded">
                                  <li v-for="event in events" :key="event" @click.prevent="interactWithForm(form, event)">
                                      <a href="" :class="{'bg-red-400': event == 'Delete'}" class="flex py-2 px-4 transition duration-300 hover:bg-gray-100">{{event}}</a>
                                  </li>
                                  </ul>
                              </div>
                          </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="!disableNextButton" class="p-4 my-2 bg-white shadow rounded text-center">
                <a class="inline-flex items-center text-xs text-customb-500 hover:text-custom-600 font-medium" href="#" @click.prevent="loadMoredata()">
                    <span class="mr-1">
                    <svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.99984 8.66669H1.33317C1.15636 8.66669 0.98679 8.73692 0.861766 8.86195C0.736742 8.98697 0.666504 9.15654 0.666504 9.33335V14.6667C0.666504 14.8435 0.736742 15.0131 0.861766 15.1381C0.98679 15.2631 1.15636 15.3334 1.33317 15.3334H3.99984C4.17665 15.3334 4.34622 15.2631 4.47124 15.1381C4.59627 15.0131 4.6665 14.8435 4.6665 14.6667V9.33335C4.6665 9.15654 4.59627 8.98697 4.47124 8.86195C4.34622 8.73692 4.17665 8.66669 3.99984 8.66669ZM3.33317 14H1.99984V10H3.33317V14ZM14.6665 6.00002H11.9998C11.823 6.00002 11.6535 6.07026 11.5284 6.19528C11.4034 6.32031 11.3332 6.48988 11.3332 6.66669V14.6667C11.3332 14.8435 11.4034 15.0131 11.5284 15.1381C11.6535 15.2631 11.823 15.3334 11.9998 15.3334H14.6665C14.8433 15.3334 15.0129 15.2631 15.1379 15.1381C15.2629 15.0131 15.3332 14.8435 15.3332 14.6667V6.66669C15.3332 6.48988 15.2629 6.32031 15.1379 6.19528C15.0129 6.07026 14.8433 6.00002 14.6665 6.00002ZM13.9998 14H12.6665V7.33335H13.9998V14ZM9.33317 0.666687H6.6665C6.48969 0.666687 6.32012 0.736925 6.1951 0.861949C6.07007 0.986973 5.99984 1.15654 5.99984 1.33335V14.6667C5.99984 14.8435 6.07007 15.0131 6.1951 15.1381C6.32012 15.2631 6.48969 15.3334 6.6665 15.3334H9.33317C9.50998 15.3334 9.67955 15.2631 9.80457 15.1381C9.9296 15.0131 9.99984 14.8435 9.99984 14.6667V1.33335C9.99984 1.15654 9.9296 0.986973 9.80457 0.861949C9.67955 0.736925 9.50998 0.666687 9.33317 0.666687ZM8.6665 14H7.33317V2.00002H8.6665V14Z" fill="#be2882"></path>
                    </svg>
                    </span>
                    <span>Load more</span>
                </a>
              </div>
            </div>
        </section>

    </div>
    <!-- Content Ends -->
  </div>
</template>


<script>
import adminUserTopNavVue from '@/components/header/adminUserTopNav.vue'

import { adminFormService } from '@/services/index'
import { mapGetters } from 'vuex'

export default {
  data(){
    return {
      forms: [],
      pageHandler:{
        data: [],
        page: 1,
        dataPerPage: 10,
        pages: [],
        lastPage: false,
        loadingMoredata: false,
        isnew: null
      },
      showDropdownKey: '',
      events: [
        'Edit form',
        'Delete',
      ],
      showCreateFormModal: false,
      newform:{
        name: ''
      }
    }
  },
  components:{
    adminUserTopNavVue
  },
  methods:{
    interactWithForm(form, event){
      switch (event) {
        case 'Edit form':          
          this.$router.push({name: 'form', params: {form_id: form.form_id}})
          break;
      
        default:
          break;
      }
      
    },
    toggleDropdown(form_id){
      console.log(this.getAllDropdownToShow); // Todo correct lick effect
      if(this.showDropdownKey === ''){
        this.showDropdownKey = form_id
      }else{
        this.showDropdownKey = ''
      }
    },
    paginate (timelines, isnew = false) {
      if (this.pageHandler.isnew) {
        // result from a search
        this.pageHandler.data = []
        this.pageHandler.page = 1,
        this.pageHandler.dataPerPage = 10,
        this.pageHandler.pages = []
      }

      let page = this.pageHandler.page;
      let perPage = this.pageHandler.dataPerPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      
      let dataWithinRange = timelines.slice(from, to);


      dataWithinRange.forEach(elem => {
        this.pageHandler.data.push(elem)
      })

      return  this.pageHandler.data;
    },
    loadMoredata(){
      this.pageHandler.loadingMoredata = true
      this.pageHandler.isnew = false
      this.pageHandler.page ++
      this.pageHandler.loadingMoredata = false
    }, 
    getForms(){
      let self = this
      
      adminFormService.getAllForms()
        .then((res)=>{
          if(res.type === 'success'){
            self.forms = res.data
            self.displayedForms
          }else if(res.type === 'error'){
            
          }
        })
    },
    createForm(){
      let self = this
      if(this.newform.name){
        adminFormService.createNewForm(this.newform.name)
          .then((res)=>{
            const {form_id, form_name, form_owner, form_token} = res.data;
            self.$router.push({name: 'form', params: {form_id: form_id}})
            self.showCreateFormModal = false
            console.log(res)            
          })
      }
    }   
  },
  computed: {
    ...mapGetters(['getAllDropdownToShow']),
    displayedForms () {
      if (this.forms) {
        return this.paginate(this.forms);
      }
    },
    disableNextButton(){
      if(this.pageHandler.data.length === this.forms.length){
        return true
      }
    }
  },
  mounted(){
    this.getForms()
  }
}
</script>

<style scoped>
.modal-wrapper{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-60%);
  width: 300px;
}
/* 'medium' breakpoint */
@media screen and (min-width: 30em) and (max-width: 60em) {
  .modal-wrapper{
    width: 350px;
  }
}

/* 'large' breakpoint */
@media screen and (min-width: 60em) {
  .modal-wrapper{
    width: 400px;
  }
}
</style>
