<template>
    <div>
        <div class="container px-4 mx-auto text-center">
            <div class="inline-flex w-full mt-4 sm:w-auto sm:mx-2 sm:mt-0 float-right">
                <button @click.prevent="addNewCondition" class="inline-flex items-center justify-center w-full px-4 py-2 text-gray-700 transition-colors duration-150 transform bg-white border border-gray-200 rounded-lg  hover:bg-gray-100 sm:w-auto focus:ring focus:ring-gray-200 focus:ring-opacity-80">
                    <i class="fas fa-plus-circle mr-2"></i> Add a new condition
                </button>
            </div>
        </div>
        <div class="clear-both"></div>
    </div>
</template>

<script>
export default {
    name: 'addNewConditionalTrigger',
    methods: {
        addNewCondition(){
            this.$emit('addNew')
        }
    }

}
</script>

<style>

</style>