import { authHeader } from '@/helper';
import { URL } from '@/helper';
import { authServices } from '@/services'
import axios from 'axios'


export const adminEventsService = {
    submitDocument
};

async function submitDocument(formData, timelineToken) {
    let token = (timelineToken) ? timelineToken : ''

    return await axios({
            method: 'post',
            url: URL+'/api/uploadSingleFile?timelineToken='+ token,            
            headers: authHeader(true),
            data: formData
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let req_res = response.data.data
                if (req_res) {
                    var res = {
                        type: 'success',
                        location: 'Events', 
                        data: req_res 
                    }
                    return res
                }
            }
        })
        .catch(function (err) {
            let msgProp = (err.response.data.message) ? err.response.data.message : null
            
            if(msgProp && msgProp == "Expired token") {
                authServices.logout()
            }
            
            return prepareError(err.response.data.message)
        });
}

function prepareError(msg){
    var res = {
        type: 'error',
        location: 'Events', 
        message: null 
    }

    res.message = (msg)
        ? msg
        : 'Sorry, we are experiencing some chanllenges. Please contact support'

    return res
}
