<template>
    <div class="rounded-lg bg-gray-50" aria-labelledby="users-tab">
        <div class="overflow-auto scrollable_table_wrapper py-2 ">
            <table class="table-auto w-full" v-if="clients">
            <tbody class="bg-white">
                <tr v-for="client in clients" :key="client.id" class="border-b border-custom-50">
                    <td class="flex items-center py-4 px-2 font-medium cursor-pointer hover:bg-gray-100" @click="trigger_getUser(client.user_name)">
                        <div class="flex px-4 py-3">                                          
                            <div>
                                <p class="text-sm font-medium">{{ client.user_name }}</p>
                            </div>
                        </div>
                    </td>
                </tr>            
            </tbody>
            </table>
        </div>
    </div>  
</template>

<script>
export default {
    props: {
        clients: Array
    },
    data() {
        return {

        }
    },
    methods: {
        trigger_getUser(userName){
            this.$emit('get-user', userName)
        }
    }
}
</script>

<style scoped>
.scrollable_table_wrapper {
    height: 60vh;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.scrollable_table_wrapper::-webkit-scrollbar {
    display: none;
}
</style>