<template>
  <div>
    <div v-if="loopQuestion.length > 0">
      <flow-form @submit="submitForm" ref="flowform" :language="language">
          <template v-for="(que, index) in loopQuestion">
            <question
              :id="que.id"              
              :key="index"              
              :type="que.type" 
              :title="que.title" 
              :options="que.options" 
              :multiple="que.multiple"
              :required="que.required"
              v-model="que.answer"
              v-if="checkIfQuestionShouldBeShown(que, index)"
            ></question>
          </template>
      </flow-form>
      <!-- <flow-form :questions="questions" @submit="submitForm"/> -->
    </div>

    <button v-if="createdTimeline.status" @click.prevent="visitTimeline()" class="inline-flex items-center py-2 px-3 mr-3 text-xs text-custom-600 font-medium rounded mt-10 bg-custom-500 text-white">
      <span>Visit Timeline</span>
    </button>
  </div>
  </template>
  
  <script>
    import FlowForm, { Question, QuestionModel, QuestionType, ChoiceOption, LanguageModel } from '@ditdot-dev/vue-flow-form'
    import { adminTimlineService } from '@/services' 
    var unique_id = require('unique-id-key')
  
    export default {
      name: 'formWrapper',
      props: {
        formSchema: Object,
        formConditions: Object
      },
      components: {
        FlowForm,
        Question
      },
      data() {
        return {  
          language: new LanguageModel({
            // Your language definitions here (optional).
            thankYouText: 'Thank you for completing the form. Click below to begin your timeline.'
          }),        
          loopQuestion: [],
          createdTimeline: {
            status: false,
            timelineObj: null,
            userEmail: ''
          }
        }
      },
      methods: {
        async visitTimeline(){
          if(!this.createdTimeline.timelineObj || !this.createdTimeline.userEmail){
            return
          }


          this.$router.push(
            {
              name: 'publicTimelines',
              query: {
                email: this.createdTimeline.userEmail
              }
            }
          )
        },
        checkIfQuestionShouldBeShown(que, index){
          let question_id = que.id,
              questions_already_answered = [],
              should_question_be_shown = true

          if(index !== 0){
            questions_already_answered = this.loopQuestion.slice(0, index)
          }
        
          // console.log('question title: '+ que.title)

          for (let i = 0; i < questions_already_answered.length; i++) {
            const element = questions_already_answered[i];

            let questions_jumpObj = element.jump,
                answer = element.answer,
                questions_optionObj = element.options
                
            const questions_to_jump_for_a_given_answer = {
              ids: []
            }

            

            if(questions_optionObj.length == 0 || !questions_jumpObj){
              continue
            }


            for (const key in questions_jumpObj) {
              if (Object.hasOwnProperty.call(questions_jumpObj, key)) {
                const element_jump = questions_jumpObj[key];
                if(key === answer){
                  questions_to_jump_for_a_given_answer.ids = element_jump
                }
                
              }
            }

            if(questions_to_jump_for_a_given_answer.ids.includes(question_id)){
                should_question_be_shown = false
                break
      
            }
            
          }

          return should_question_be_shown
        },
        async submitForm(questionList) {
          console.log(questionList)
          let answers = [],
              self = this

          questionList.forEach(question => {
            const { answered, answer, title } = question

            if(answered){
              answers.push({
                answer: answer,
                ques: title
              })
            }
          })

          await adminTimlineService.startNewTimeline(answers)
          .then((res)=>{
            console.log(res)
            if(res.type === 'success'){
              self.createdTimeline.timelineObj = (res.data) ? res.data : {}
              self.createdTimeline.status = true
              self.createdTimeline.userEmail = (res.data.client_email) ? res.data.client_email : ''
            }else if(res.type === 'error'){
              
            }
          })

        },
        getQuestionType(field_type){
          if(field_type === 'text'){
            return QuestionType.Text
          }
          if(field_type === 'email'){
            return QuestionType.Email
          }
          if(field_type === 'date'){
            return QuestionType.Date
          }
          if(field_type === 'dropdown'){
            return QuestionType.Dropdown
          }
          if(field_type === 'radio'){
            return QuestionType.Dropdown
          }
          if(field_type === 'checkboxes'){
            return QuestionType.MultipleChoice
          }
          if(field_type === 'paragraph'){
            return QuestionType.LongText
          }
        },
        processFields(){
            console.log('working');
            let fields = this.formSchema.fields,
                conditions = this.formConditions.conditions

            conditions = conditions.filter(elem=> {
              return elem.do_condition === "hide" 
            })

            for (const field of fields) {
              const { label, field_options, field_type, required  } = field
              let type = null
              type = this.getQuestionType(field_type)
              
              const question =  new QuestionModel(
                {
                  title: label,
                  required: (required) ? true : false,
                  type: type,
                  jump: null,
                  id: unique_id.AlphaNum(10),
                  options: null,
                  answer: '',
                  multiple: (field_type == 'checkboxes') ? true : false
                }
              )
              

              if(field_type === 'dropdown' || 'radio'){
                let options =  []
                let jumpConditions =  {}

                if(field_options.options){
                  field_options.options.forEach((elem, index) => {
                    let value = (elem.label)? elem.label.replace(" ", "_") : ''
                    options.push(
                      new ChoiceOption({
                        label: elem.label,
                        value: value
                      })
                    )

                    jumpConditions[value] = []
                  });
                }

                question.options = options
                question.jump = jumpConditions
              }

              
              this.loopQuestion.push(question)                          
            }

            // Handle Conditions
            for(const condition of conditions){
              let triggerField = null,
                  fieldActedUpon = null,
                  triggerField_jump = null
              
              triggerField = this.loopQuestion.find(elem => {
                return elem.title === condition.if_condition.fieldLabel
              })
              
              fieldActedUpon = this.loopQuestion.find(elem => {
                return elem.title === condition.on_condition.fieldLabel
              })

              if(! triggerField && fieldActedUpon){
                return
              }

              triggerField_jump = triggerField.jump

              switch (condition.state_condition) {
                case "isequalto": 

                  for (const key in triggerField_jump) {
                    if (Object.hasOwnProperty.call(triggerField_jump, key)) {
                      const element = triggerField_jump[key];

                      if(key === condition.value_condition){
                        if(fieldActedUpon && fieldActedUpon.id){
                          element.push((fieldActedUpon.id))
                        }
                      }
                      
                    }
                  }
                                    
                  break;
                case "isnotequalto":

                  for (const key in triggerField_jump) {
                    if (Object.hasOwnProperty.call(triggerField_jump, key)) {
                      const element = triggerField_jump[key];

                      if(key !== condition.value_condition){
                        if(fieldActedUpon && fieldActedUpon.id){
                          element.push((fieldActedUpon.id))
                        }
                      }
                      
                    }
                  }
    
                  break;
                case "isfilled":
                  
                  break;
                case "isempty":
                  
                  break;
              }              

            }

            return this.loopQuestion

        }
      },
      mounted(){
        if(this.formSchema && this.formConditions){
          console.log('working init')
          this.processFields()
        }
      }
    }
  </script>
  
  <style scoped>
    /* Import Vue Flow Form base CSS */
    @import '@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
    /* Import one of the Vue Flow Form CSS themes (optional) */
    @import '@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css';
    /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-green.css'; */
    /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css'; */
  </style>