const state = {
    location: null,
    type: null,
    message: null
}

const getters = {
    getAlertLocation: (state) =>{
        return state.location
    },
    getAlertType: (state) =>{
        return state.type
    },
    getAlertMessage: (state) =>{
        return state.message
    }
}

const actions = {
    success({ commit }, alertObj) {
        commit('success', alertObj);
    },
    error({ commit }, alertObj) {
        commit('error', alertObj);
    },
    clear({ commit }) {
        commit('clear');
    }
}

const mutations = {
    success(state, alertObj) {
        let { location, message } = alertObj
        state.location = location;
        state.type = 'success';
        state.message = message;
    },
    error(state, alertObj) {
        let { location, message } = alertObj
        state.location = location;
        state.type = 'error';
        state.message = message;
    },
    clear(state) {
        state.location = null;
        state.type = null;
        state.message = null;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}