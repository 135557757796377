<template>
  <div class="timeline_display">
    <div id="timeline_slide">
      <button @click="cancelSelection()" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center selectedItemModalCloser" data-modal-hide="defaultModal">
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Close modal</span>
      </button>
      <div class="timeline_slide_wrapper" v-if="selectedItem && !timelineEventHasChanged">
        <timelineOnSelectViewAndEditorVue :item="selectedItem" :events="events" :timelineDetails="timelineDetails" @relay-events-one="relayEventToReachParent_two"/>
      </div>
    </div>
    <div id="visualization"></div>
  </div>
</template>

<script>
import { DataSet, Timeline } from "vis-timeline/standalone";
import "vis-timeline/styles/vis-timeline-graph2d.css";
import timelineOnSelectViewAndEditorVue from './timelineOnSelectViewAndEditor.vue';

export default {
  name: 'timelineViz',
  props: {
    events: Array,
    timelineDetails: Object
  },
  components: {
    timelineOnSelectViewAndEditorVue
  },
  data(){
    return {
      itemsInDisplay: null,
      parsedEvents: {
        withDate: null,
        withoutDate: null
      },
      selectedItem: null,
      timeline: null,
      timelineEventHasChanged: false
    }
  },
  methods:{
    relayEventToReachParent_two(eventEditedObj){
      console.log('test')
      console.log(eventEditedObj)
      this.$emit('save-events', eventEditedObj)
    },
    async prepareData(){
      if (this.events) {
          this.parsedEvents.withDate = this.events.filter((elem)=>{
            if(elem.event_happen){
              try {
                let dataArr = elem.event_happen.split('-')
                /**
                 * Format "12-16-2020" MM-DD-YYY
                 */
                
                let day = dataArr[1],
                monthIndex =  Number(dataArr[0]) - 1,
                year = dataArr[2],
                date = new Date(year, monthIndex, day)
                
                if(date && date.valueOf()){                 
                  elem.start = date.valueOf()
                  return true
                }

              } catch (error) {   
                console.log(error)             
                return false
              }
            }else{
              return false
            }
          })
          this.parsedEvents.withoutDate = this.events.filter((elem)=>{
            if(!elem.event_happen){
              return true
            }else{
              return false
            }
          })
        }
    },
    prepareTimeline(dataArr){
      const container = document.querySelector('#visualization');
      var source = document.getElementById('item-template').innerHTML; // from index.html
      var template = Handlebars.compile(source);
      var self = this

      // Create a DataSet (allows two way data-binding)
      const items = new DataSet(dataArr);
      this.itemsInDisplay = items

      // Configuration for the Timeline
      const options = {
        stack: false,
        height: '100%',
        margin: 170,
        xss: {
          disabled: true
        },
        template: template
      }

      // Create a Timeline
      const timeline = new Timeline(container, items, options);

      // add event listener
      // timeline.on('click', self.onSelect);
      timeline.on('doubleClick', self.onSelect);

      this.timeline = timeline

    },
    onSelect (properties) {
      const itemID = properties.item
      
      this.selectedItem = this.itemsInDisplay.get(itemID)
      // console.log(this.selectedItem)
      
      document.querySelector('#timeline_slide').style.display = 'block'
    },
    cancelSelection(){
      document.querySelector('#timeline_slide').style.display = 'none'
      this.selectedItem = null
      
    }
  },
  watch:{
    events(){
      console.log('changed');
      // close open modal
      this.timelineEventHasChanged = true

      this.timeline.destroy()

      this.prepareData()
      .then((e)=>{
        this.prepareTimeline(this.parsedEvents.withDate)
        this.cancelSelection()
        this.timelineEventHasChanged = false
      })
    }
  },
  mounted(){
    this.prepareData()
      .then((e)=>{
        this.prepareTimeline(this.parsedEvents.withDate)
      })

  }
}
</script>

<style scoped>
.timeline_display{
  position: relative;
}
#timeline_slide{
  /* display: block; */
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, .7);
  z-index: 100;
}
.timeline_slide_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>

