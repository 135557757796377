<template>
  <div class="antialiased bg-body text-body font-body admin-port">
    <!-- Content Begins -->
    <div class="mx-auto lg:ml-80">

      <adminUserTopNavVue/>
            
      <!-- Analytics begins -->
      <section class="py-8">
        <div class="container px-4 mx-auto">
          <div class="bg-white rounded p-3">
          </div>
        </div>
      </section>
      <!-- Analytics ends -->  

    </div>
    <!-- Content Starts -->
  </div>
</template>

<script>
import adminUserTopNavVue from '@/components/header/adminUserTopNav.vue'

export default {
  components:{
    adminUserTopNavVue,
  },
  methods: {

  },
  mounted(){
  }
}
</script>

<style scoped>
</style>