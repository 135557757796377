import { adminService } from '@/services'

export const userMixins = {
    props: {
        data: Object
    },
    data(){
        return {
            date_registered: null,
            id: null,
            name: null,
            token: null,
            user_name: null,
            user_password: null,
            user_type: null,
            confirm_password: null,
            formErrors: {
                password_do_not_match: false,
            },
            password_changed: false,
            success_createUser: false,
            error_createUser: false 
        }
    },
    watch: {
        data: {
            deep: true,
            handler(){
                
            }
        },
        user_password(){
            if(this.password_changed){
                if(this.confirm_password !== this.user_password){
                    this.formErrors.password_do_not_match = true
                }else{
                    this.formErrors.password_do_not_match = false                    
                }
            }
            
        },
        confirm_password(){
            if(this.password_changed){
                if(this.confirm_password !== this.user_password){
                    this.formErrors.password_do_not_match = true
                }else{
                    this.formErrors.password_do_not_match = false                    
                }
            }
            
        }
    },
    methods: {
        async saveUserDetails(){
            let self = this,
                requestBody = {
                    name: self.name, 
                    user_password: self.user_password
                }

            if(self.password_changed){
                if(self.user_password !== self.confirm_password){
                    self.formErrors.password_do_not_match = true
                    return
                }
            }
            
            await adminService.saveUserDetails(self.id, requestBody)
                .then((res)=>{
                    console.log(res)
                    if(res.data){
                        self.$emit('reload-users', self.user_name)
                    }        
                })
        },
        async createUser_successProcess(){
            this.success_createUser = true
            await this.getUsers()
            setTimeout(()=>{
                if(this.addNewUser){
                    this.addNewUser = false
                }
            }, 3000)
        },
        createUser_errorProcess(){
            this.error_createUser = true
        },
        async createNewUser(admin = false){
            let self = this,
            requestBody = {
                name: self.name, 
                user_name: self.user_name, 
                user_password: self.user_password,
                user_type: (admin) ? 'admin' : 'client'
            }

            if(self.password_changed){
                if(self.user_password !== self.confirm_password){
                    self.formErrors.password_do_not_match = true
                    return
                }
            }

            await adminService.createNewUser(requestBody)
            .then(async (res)=>{
                console.log(res)
                if(res.data){
                    await self.createUser_successProcess()
                }        
            })
            .catch((err)=>{
                self.createUser_errorProcess()
            })

        },
        processDataReceived(){
            if(this.data){
                const {date_registered_UNIX, id, name, token, user_name, user_password, user_type} = this.data
                this.date_registered = date_registered_UNIX
                this.id = id
                this.name = name
                this.token = token
                this.user_name = user_name
                this.user_password = user_password
                this.confirm_password = user_password
                this.user_type = user_type

                this.password_changed = false
                this.formErrors.password_do_not_match = false        
            }
        }
    },
    mounted(){
        this.processDataReceived()
    }
  }