import adminUserTopNavVue from '@/components/header/adminUserTopNav.vue'
import { adminTimlineService } from '@/services/index'
import { mapGetters } from 'vuex'

export const dashboardTimelineMixins = {
    data(){
        return {
            publicUserEmail: null,
            timelines: [],
            pageHandler:{
                data: [],
                page: 1,
                dataPerPage: 10,
                pages: [],
                lastPage: false,
                loadingMoredata: false,
                isnew: null
            },
            showDropdownKey: '',
            events: [
                'Visit Timeline',
                'Dowload timeline',
                'Dowload events',
                'Send to archive',
                'Delete',
            ],
            publicEvents: [
                'Visit Timeline',
                'Dowload timeline',
                'Dowload events',
                'Delete',
            ]
        }
      },
    components:{
        adminUserTopNavVue
    },
    methods:{
        getEventCount(userEvents){
            try {
            let eventArrInJSON = JSON.parse(userEvents)

            let count = eventArrInJSON.length

            if(count && count > 1){
                return count +" events"
            }else if(count && count == 1){
                return count +" event"
            }else{
                return "No events"
            }
            } catch (error) {
            return "No events"
            }
        },
        interactWithTimeline(timeline, event, isPublic = false){
            switch (event) {
            case 'Visit Timeline':    
                (isPublic) ?
                this.$router.push({name: 'publicTimeline', params: {token: timeline.token}}) :
                this.$router.push({name: 'timeline', params: {token: timeline.token}})
                break;
            
            default:
                break;
            }
            
        },
        publicInteractWithTimeline(timeline, event){
            switch (event) {
            case 'Visit Timeline':          
                this.$router.push({name: 'publicTimeline', params: {token: timeline.timeline_token}})
                break;
            
            default:
                break;
            }
            
        },
        toggleDropdown(token){
            console.log(this.getAllDropdownToShow); // Todo correct lick effect
            if(this.showDropdownKey === ''){
            this.showDropdownKey = token
            }else{
            this.showDropdownKey = ''
            }
        },
        paginate (timelines, isnew = false) {
            if (this.pageHandler.isnew) {
            // result from a search
            this.pageHandler.data = []
            this.pageHandler.page = 1,
            this.pageHandler.dataPerPage = 10,
            this.pageHandler.pages = []
            }

            let page = this.pageHandler.page;
            let perPage = this.pageHandler.dataPerPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            
            let dataWithinRange = timelines.slice(from, to);


            dataWithinRange.forEach(elem => {
            this.pageHandler.data.push(elem)
            })

            return  this.pageHandler.data;
        },
        loadMoredata(){
            this.pageHandler.loadingMoredata = true
            this.pageHandler.isnew = false
            this.pageHandler.page ++
            this.pageHandler.loadingMoredata = false
        },
        async getAllTimelines(){
            let self = this
            await adminTimlineService.getAllTimelines()
                .then((res)=>{
                    if(res.type === 'success'){
                        self.timelines = res.data
                        console.log(self.timelines)
                    }else if(res.type === 'error'){
                        
                    }
                })
        },
        async getUserTimelines(user = null){
            let self = this
            await adminTimlineService.getUserTimelines(user.email)
                .then((res)=>{
                    if(res.type === 'success'){
                        self.timelines = res.data
                        console.log(self.timelines)
                    }else if(res.type === 'error'){
                        
                    }
                })
        },
        async getPublicUserTimelines(user = null){
            let self = this
            await adminTimlineService.getUserTimelines(user.email, true)
                .then((res)=>{
                    if(res.type === 'success'){
                        self.timelines = res.data
                        console.log(self.timelines)
                    }else if(res.type === 'error'){
                        
                    }
                })
        },
    },
    computed: {
        ...mapGetters(['getAllDropdownToShow']),
        displayedTimelines () {
            if (this.timelines) {
            return this.paginate(this.timelines);
            }
        },
        disableNextButton(){
            if(this.pageHandler.data.length === this.timelines.length){
            return true
            }
        }
    }
}