<template>
  <!-- Content Header Begins -->
  <section class="py-8">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap items-stretch -m-4">
          <div class="w-full lg:w-8/12 p-4">
            <div class="bg-white shadow rounded overflow-hidden">
              <div class="p-4">
                <div class="flex justify-between items-center mb-6">
                  <a @click.prevent="goBack()" class="inline-block py-1 px-2 bg-customb-500 rounded-full text-xs text-white transition duration-200" href="#">&lt;&lt; Go back</a>          
                </div>
                <div class="">
                  <h3 class="font-medium text-md" v-if="headerData.claims">
                    Timeline Claim: 
                    {{headerData.claims.join(', ')}}
                  </h3>
                  <h3 class="font-medium text-md" v-else>
                    Timeline
                  </h3>
                </div>
              </div>
              <!-- <div class="px-4 py-2 flex items-center justify-between bg-lightGray-50">           
                <div>
                  <span class="block mb-2 text-sm text-gray-500 font-medium tracking-normal">
                    A claim of type <span class="italic underline decoration-dotted  font-bold">{{(headerData.claims) ? headerData.claims.join(', ') : ' '}}</span> requires the following event type(s) before your application can be successfully processed</span>
                    <div>
                      <span class="inline-block py-1 px-3 mr-1 my-1 rounded-full bg-green-50 text-xs text-gray-500">20 September 2021</span>
                      <span class="inline-block py-1 px-3 mx-1 my-1 rounded-full bg-green-50 text-xs text-gray-500">20 September 2021</span>
                      <span class="inline-block py-1 px-3 mx-1 my-1 rounded-full bg-orange-50 text-xs text-red-500">20 September 2021</span>
                      <span class="inline-block py-1 px-3 mx-1 my-1 rounded-full bg-orange-50 text-xs text-red-500">20 September 2021</span>
                    </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="w-full lg:w-4/12 p-4">
            <div class="p-4 bg-white rounded">
              <div class="flex mb-6 justify-between items-center">
                <div>              
                  <h3 class="text-sm font-medium inline-block">
                    {{(headerData.userSection.clientName) ? headerData.userSection.clientName : ' '}}
                  </h3>
                </div>
                <div class="ml-auto inline-block">
                  <div class="timelineDropdownElem dropdown-wrapper relative mx-1">
                    <button class="p-2 hover:bg-gray-200 bg-gray-100 rounded" @click="showUserDropdown = !showUserDropdown">
                      <svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.99984 9.33335C8.73622 9.33335 9.33317 8.7364 9.33317 8.00002C9.33317 7.26364 8.73622 6.66669 7.99984 6.66669C7.26346 6.66669 6.6665 7.26364 6.6665 8.00002C6.6665 8.7364 7.26346 9.33335 7.99984 9.33335Z" fill="#374151"></path>
                        <path d="M3.33333 9.33335C4.06971 9.33335 4.66667 8.7364 4.66667 8.00002C4.66667 7.26364 4.06971 6.66669 3.33333 6.66669C2.59695 6.66669 2 7.26364 2 8.00002C2 8.7364 2.59695 9.33335 3.33333 9.33335Z" fill="#374151"></path>
                        <path d="M12.6668 9.33335C13.4032 9.33335 14.0002 8.7364 14.0002 8.00002C14.0002 7.26364 13.4032 6.66669 12.6668 6.66669C11.9304 6.66669 11.3335 7.26364 11.3335 8.00002C11.3335 8.7364 11.9304 9.33335 12.6668 9.33335Z" fill="#374151"></path>
                      </svg>
                    </button>
                    <transition name="fade">
                      <div v-if="showUserDropdown" class="z-10 bg-white dropdown-menu  mt-1 rounded absolute z-10 shadow-lg w-40 max-w-xs right-0">
                          <ul class="list-none overflow-hidden rounded">
                          <li v-for="event in events" :key="event" @click.prevent="interactWithTimeline(event)">
                              <span :class="{'bg-red-400': event == 'Delete Timeline'}" class="hover:opacity-50 cursor-pointer flex py-2 px-4 transition duration-300 hover:bg-gray-100">{{event}}</span>
                          </li>
                          </ul>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
              <div class="flex mb-2 justify-between items-center">
                <h4 class="text-xs font-medium">Started on</h4>
                <span class="inline-block py-1 px-2 text-xs text-gray-500">
                  {{(headerData.userSection.startDate) ? headerData.userSection.startDate : ' '}}
                </span>
              </div>
              <div class="flex mb-5 justify-between items-center">
                <h4 class="text-xs font-medium">Created on</h4>
                <span class="text-xs text-customb-400 font-medium">
                  {{(headerData.userSection.createdDate) ? headerData.userSection.createdDate : ' '}}
                </span>
              </div>
              <div class="flex items-ceenter justify-between border-t border-gray-50 pt-4">
                <button @click.prevent="trigger_archiveTimeline()" class="py-1 px-2 bg-customb-500 rounded text-xs text-white transition duration-200" >Archive timeline</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
  <!-- Content Header ends -->
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'timelineHeader',
  data(){
    return {
      claims: null,
      events: [
        'View User',
        'Email User',
        'Delete Timeline',
      ],
      showUserDropdown: false
     }
  },
  props: {
    headerData: Object
  },
  components:{

  },
  methods:{
    goBack(){
      this.$router.push({name: 'timelines'})
    },

    async trigger_archiveTimeline(){
      this.$emit('sent-timeline-to-archive')
    },
    interactWithTimeline(event){

    }
  },
  mounted(){
  },
  computed: {
    ...mapGetters(['getAllDropdownToShow', 'getFullScreenDisplayStatus']),
  }
}
</script>

<style>
/* Animations */
.fade-enter-active, .fade-leave-active {
  transition: opacity .1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
