<template>
    <div class='conditional-editor lg:px-10 px-4 py-2'>
        <div class="relative block p-4 lg:w-1/2 md:w-2/3 w-full my-4 bg-white rounded-lg border border-gray-200 shadow-sm ">
            <ul class="text-gray-900 bg-white">                        
                <li class="py-2 px-4 w-full flex justify-between">
                    <div class="w-3/12">
                        IF
                    </div>
                    <div class="w-9/12">
                        <select v-model="condition.if_condition.cid" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:none block w-full p-1.5">
                            <option v-for="field in fields" :value="field.cid" :key="field.cid" :class="{'bg-green-500': condition.if_condition.cid == field.cid}">{{field.label}}</option>
                        </select>
                    </div>
                </li>
                <li class="py-2 px-4 w-full flex justify-between">
                    <div class="w-3/12">
                        STATE
                    </div>
                    <div class="w-9/12">
                        <select v-model="condition.state_condition" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:none block w-full p-1.5">
                            <option v-for="state in possibleStates" :key="state.value" :value="state.value" :class="{'bg-green-500': condition.state_condition == state.value}">{{state.label}}</option>                        
                        </select>
                    </div>
                </li>
                <li v-if="(condition.state_condition != 'isfilled') && (condition.state_condition != 'isempty') && condition.state_condition" class="py-2 px-4 w-full flex justify-between">
                    <div class="w-3/12">
                        VALUE
                    </div>
                    <!-- if state is isfilled or isempty -->
                    <!-- if state is isequalto or notequalto -->

                    <div class="w-9/12">
                        <!-- 
                            
                         -->
                        <div v-if="doesSelectedFieldHaveMultipleOptions">
                            <select v-model="condition.value_condition" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:none block w-full p-1.5">
                                <option v-for="option in getPossibleOptions" :key="option.label" :value="option.label">{{option.label}}</option>
                            </select>
                        </div>

                        <div v-else>
                            <input v-model="condition.value_condition" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:none block w-full p-1.5">
                        </div>
                        

                    </div>
                </li>
            </ul>
        </div>
        <div class="relative block p-4 lg:w-1/2 md:w-2/3 w-full my-4 bg-white rounded-lg border border-gray-200 shadow-sm ">
            <ul class="text-gray-900 bg-white">                        
                <li class="py-2 px-4 w-full flex justify-between">
                    <div class="w-3/12">
                        DO
                    </div>
                    <div class="w-9/12">
                        <select v-model="condition.do_condition" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:none block w-full p-1.5">
                            <option v-for="action in possibleActions" :key="action" :value="action" :class="{'bg-green-500': condition.do_condition == action}">{{action}}</option> 
                        </select>
                    </div>
                </li>
                <li class="py-2 px-4 w-full flex justify-between">
                    <div class="w-3/12">
                        FIELD
                    </div>
                    <div class="w-9/12">
                        <select v-model="condition.on_condition.cid" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:none block w-full p-1.5">
                            <option v-for="field in possibleFieldThatCanBeActedOn" :value="field.cid" :key="field.cid" :class="{'bg-green-500': condition.on_condition.cid == field.cid}">{{field.label}}</option>
                        </select>
                    </div>
                </li>
            </ul>
        </div>
        <div class="relative block lg:w-1/2 md:w-2/3 w-full my-2">
            <div class="inline-flex w-full sm:w-auto float-right">
                <button @click="saveCondition()" class="inline-flex items-center justify-center w-full px-4 py-2 text-gray-700 transition-colors duration-150 transform bg-white border border-gray-200 rounded-lg  hover:bg-gray-100 sm:w-auto ">
                    SAVE
                </button>
            </div>
            <div class="clear-both"></div>
        </div>
    </div>  
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'conditionalEditiorBlock',
    props: {
        condition: Object,
        fields: Array
    },
    data(){
        return {
            possibleStates: [
                { 
                    value: 'isequalto',
                    label: 'Is Equal To',
                },
                { 
                    value: 'isnotequalto',
                    label: 'Is Not Equal To',
                },
                { 
                    value: 'isfilled',
                    label: 'Is Filled',
                },
                { 
                    value: 'isempty',
                    label: 'Is Empty',
                },
            ],
            possibleActions: [
                "show",
                "hide"
            ]
        }
    },
    computed:{
        doesSelectedFieldHaveMultipleOptions(){
            if(this.condition.if_condition.cid){
                let selectedField = this.fields.find((elem)=>{
                    return elem.cid == this.condition.if_condition.cid
                })

                let fieldTypeWithOptions = ["checkboxes", "dropdown", "radio"]

                if(fieldTypeWithOptions.includes(selectedField.field_type)){
                    return true
                }else{
                    return false
                }

            }
        },
        getPossibleOptions(){
            if(this.condition.if_condition.cid){
                let selectedField = this.fields.find((elem)=>{
                    return elem.cid == this.condition.if_condition.cid
                })

                if(selectedField.field_options.options){
                    return selectedField.field_options.options.filter((elem)=>{
                        return elem.label
                    })
                }else{
                    return false
                }

            }
        },
        possibleFieldThatCanBeActedOn(){
            if(this.condition.if_condition.cid){
                const index = this.fields.findIndex((elem)=>{
                    return elem.cid == this.condition.if_condition.cid
                })

                return this.fields.slice(index + 1)
            }
        }
    },
    methods: {
        ...mapActions('formModule', ['setActiveCondition']),
        saveCondition(){
            this.setActiveCondition(this.condition)
            this.$emit('conditionSave')
        }
    },
    watch: {
        condition: {
            handler(newValue, oldValue) {
                if(newValue.if_condition.cid && !newValue.if_condition.label){
                    let field = this.fields.find((elem)=>{
                        return elem.cid == newValue.if_condition.cid
                    })

                    this.condition.if_condition.fieldLabel = field.label
                }

                if(newValue.on_condition.cid && !newValue.on_condition.label){
                    let field = this.fields.find((elem)=>{
                        return elem.cid == newValue.on_condition.cid
                    })

                    this.condition.on_condition.fieldLabel = field.label
                }

            },
            deep: true
        }
    }
}
</script>

<style>

</style>