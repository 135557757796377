<template>
    <div class="bg-gray-200 font-sans leading-normal tracking-normal new-body">

        <!--Header-->
        <div class="w-full m-0 p-0 bg-cover bg-bottom default-hero" :style="image">
            <div class="container max-w-4xl mx-auto pt-16 md:pt-32 text-center break-normal">
                <!--Title-->
                    <p class="text-white font-extrabold text-3xl md:text-5xl">
                        {{getActiveFormName}}
                    </p>
                    <p class="text-xl md:text-2xl text-gray-500">Please fill out the form</p>
            </div>
        </div>
        
        <!--Container-->
        <div class="container px-4 md:px-0 max-w-6xl mx-auto -mt-32">
            
            <div class="mx-0 sm:mx-6">

                <div class="bg-gray-200 w-full text-xl md:text-2xl text-gray-800 leading-normal rounded-t">
                    
                <!--Lead Card-->
                <div class="flex h-full bg-white rounded overflow-hidden shadow-lg form-container">
                    <div class="w-full flex flex-col flex-grow flex-shrink">
                        <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg px-5">
                            <div v-if="formSchema">
                                <formWrapperVue :form-schema="formSchema" :form-conditions="formConditions"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/Lead Card-->


                <!-- Empty space -->
                <div class="flex flex-wrap justify-between pt-12 -mx-6">

                </div>
                <!--/ Empty space -->
                        
            </div>
            
        </div>


        </div>
    </div>
</template>

<script>
import formWrapperVue from '@/components/forms/public/formWrapper.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        formWrapperVue
    },
    data(){
        return {
            image: { 
                backgroundImage: "url("+require('@/assets/images/cover.jpg')+")" 
            },
            formSchema: null,
            formConditions: null,
            formName: null
        }
    },
    computed: {
        ...mapGetters('formModule', ['getActiveFormName'])
    },
    props: {
        form: Object
    },
    mounted(){
        const { form_conditions, form_name, form_schema } = this.form
        this.formSchema = JSON.parse(form_schema)
        this.formConditions = JSON.parse(form_conditions)
        this.formName = form_name
    }

}
</script>

<style scoped>
.default-hero{
    height: 60vh; 
    max-height:460px;
}
.form-container{
    min-height: 500px;
}
.form-footer{
    z-index: -10;
}
.new-body{
    height: 100vh;
}
</style>