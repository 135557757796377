<template>
  <div class="mt-3 w-full px-8">                            
        <form>
            <div class="mb-4">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
            <textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 " placeholder="Leave a comment..."></textarea>
            </div>
            <button type="submit" class="text-white bg-customb-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Send</button>
        </form>
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    prop: {
        data: Object
    }
}
</script>

<style>

</style>