var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.event)?_c('div',{staticClass:"inline-block"},[_c('div',{staticClass:"inline-block mx-1 tags_button_wrapper",on:{"click":function($event){$event.preventDefault();return _vm.addtag(_vm.event, 'star')}}},[_c('i',{staticClass:"far fa-star inline-block bg-white rounded-lg border custom-border drop-shadow-sm hover:bg-gray-100 rounded-full tags_button",class:{
            'bg-custom-500 text-white': _vm.doesTagContain(_vm.event, 'star'), 
            'bg-white text-gray-800': ! _vm.doesTagContain(_vm.event, 'star'), 
        }})]),_c('div',{staticClass:"inline-block mx-1 tags_button_wrapper",on:{"click":function($event){$event.preventDefault();return _vm.addtag(_vm.event, 'exclamation')}}},[_c('i',{staticClass:"fas fa-exclamation inline-block rounded-lg border custom-border drop-shadow-sm hover:bg-gray-100 rounded-full tags_button",class:{
            'bg-custom-500 text-white': _vm.doesTagContain(_vm.event, 'exclamation'), 
            'bg-white text-gray-800': ! _vm.doesTagContain(_vm.event, 'exclamation'), 
        }})]),_c('div',{staticClass:"inline-block mx-1 tags_button_wrapper",on:{"click":function($event){$event.preventDefault();return _vm.addtag(_vm.event, 'times')}}},[_c('i',{staticClass:"fas fa-ban inline-block bg-white rounded-lg border custom-border drop-shadow-sm hover:bg-gray-100 rounded-full tags_button",class:{
            'bg-custom-500 text-white': _vm.doesTagContain(_vm.event, 'times'), 
            'bg-white text-gray-800': ! _vm.doesTagContain(_vm.event, 'times'), 
        }})]),_c('div',{staticClass:"inline-block mx-1 tags_button_wrapper",on:{"click":function($event){$event.preventDefault();return _vm.addtag(_vm.event, 'bulb')}}},[_c('i',{staticClass:"fas fa-lightbulb inline-block bg-white rounded-lg border custom-border drop-shadow-sm hover:bg-gray-100 rounded-full tags_button",class:{
            'bg-custom-500 text-white': _vm.doesTagContain(_vm.event, 'bulb'), 
            'bg-white text-gray-800': ! _vm.doesTagContain(_vm.event, 'bulb'), 
        }})]),_c('div',{staticClass:"inline-block mx-1 tags_button_wrapper",on:{"click":function($event){$event.preventDefault();return _vm.addtag(_vm.event, 'question')}}},[_c('i',{staticClass:"fas fa-question inline-block bg-white rounded-lg border custom-border drop-shadow-sm hover:bg-gray-100 rounded-full tags_button",class:{
            'bg-custom-500 text-white': _vm.doesTagContain(_vm.event, 'question'), 
            'bg-white text-gray-800': ! _vm.doesTagContain(_vm.event, 'question'), 
        }})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }