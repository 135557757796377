<template>
    <div class="mt-3 w-full px-8">                            
        <form>
            <div class="mb-2">
                <label class="block mb-2 text-sm font-medium text-gray-900">Username/Email: <span class="text-gray-800">{{ user_name }}</span></label>
            </div>
            <div class="mb-6">
                <label for="text" class="block mb-2 text-sm font-medium text-gray-900">Name</label>
                <input v-model="name" type="text" class="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2">
            </div>
            <div class="mb-6">
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                <input v-model="user_password" type="password" class="p-2 bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg" @change="password_changed = true">
            </div>
            <div class="mb-6" v-if="password_changed">
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Confirm password</label>
                <input v-model="confirm_password" type="password" class="p-2 bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg">
                <p v-if="formErrors.password_do_not_match" class="mt-2 text-sm text-red-600"><span class="font-medium">Oops!</span> Password do not match</p>
            </div>
            <button 
                @click.prevent="saveUserDetails()" 
                type="submit" 
                class="text-white bg-customb-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center inline-block"
                :disabled="formErrors.password_do_not_match"
                :class="{'cursor-not-allowed': formErrors.password_do_not_match
            }"
            >
                Save
            </button>
        </form>
    </div>
</template>

<script>
import { userMixins } from '@/mixins'

export default {
    mixins: [
        userMixins
    ]
}
</script>

<style>

</style>