<template>
<div @click="disableAllDropdowns($event)">
  <section v-if="getAccessTimelineModalStatus" class="fixed top-0 left-0 right-0 bottom-0 flex items-center w-full h-full p-4 bg-gray-800 bg-opacity-80 overflow-y-auto z-50">
    <div class="max-w-lg w-full m-auto p-8 bg-white rounded-md">
      <h3 class="mb-6 text-xl font-semibold text-gray-900 text-center">Access Existing Timeline</h3>
      <form action="">
        <div class="mb-8">
          <input class="block h-12 w-full py-2 px-3 appearance-none border border-gray-200 rounded-lg shadow-md leading-6 placeholder-gray-300 focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50" v-model="email" type="email" placeholder="Email Address">
        </div>
        <div class="flex flex-wrap justify-end -m-2">
          <div class="w-full md:w-1/2 p-2">
            <button @click.prevent="toggleAccessTimelineModal()" class="flex flex-wrap justify-center w-full px-4 py-2.5 bg-white font-medium text-base text-gray-500 hover:text-gray-600 border border-gray-200 hover:border-gray-300 rounded-md shadow-button">
              <p>Cancel</p>
            </button>
          </div>
          <div class="w-full md:w-1/2 p-2">
            <button @click.prevent="accessUserTimeline()" class="flex flex-wrap justify-center w-full px-4 py-2.5 bg-custom-500 font-medium text-base text-white border rounded-md shadow-button">
              <p>Proceed</p>
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>

  <HeaderComponent/>
  <router-view/>
  <FooterComponent/>
</div>
</template>

<script>
import HeaderComponent from "@/components/header/HeaderComponent.vue";
import FooterComponent from "@/components/footer/FooterComponent.vue";
import { mapActions, mapGetters } from 'vuex'

export default {
    data(){
      return {
        allDropdownClasses: [
          'timelineDropdownElem'
        ],
        email: ''
      }
    },
    components: { 
      HeaderComponent,
      FooterComponent
    },
    methods: {
      ...mapActions(['changeNav', 'setDropdownToShow', 'toggleAccessTimelineModal']),
      trackRoute(to){
        to.matched.forEach((match)=>{
          if(match.path == '/admin' || match.path == '/client-portal'){
            this.changeNav('admin')          
          }else if(match.path == ''){
            this.changeNav('main')          
          }
        })
      },
      disableAllDropdowns(e){
        var classespresent = [],
        allDropdownToShow = []
        
        e.target.classList.forEach((elemt)=>{
          classespresent.push(elemt)
        })
                
        this.allDropdownClasses.forEach((elem) => {

          if(
              classespresent.find((el)=>{return el == elem})
            ){
              allDropdownToShow.push(elem)
            }
        })
        this.setDropdownToShow(allDropdownToShow)
      },
      accessUserTimeline(){
        const { email } = this.$route.query

        this.toggleAccessTimelineModal()
        if(email == this.email){
          return
        }

        this.$router.push(
          {
            name: 'publicTimelines',
            query: {
              email: this.email
            }
          }
        )
      }
    },
    watch:{
      $route(to, from){
        this.trackRoute(to)
      }
    },
    computed:{
    ...mapGetters(['getAccessTimelineModalStatus'])
    },
    created(){
      let route = this.$route
      this.trackRoute(route)
    }
}
</script>

<style>
@import 'https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap';
@import '@/assets/css/custom-style.css';
.bg-body {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 251/var(--tw-bg-opacity));
}
body {
    font-family: DM Sans,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    min-height: 100vh;
}
.admin-port{
  height: 100%;
  min-height: 100vh;
}

/* Animations */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Slide-in-left animation*/
.slide-in-left-enter-active, .slide-in-left-leave-active {
  transition: all .5s;
  transform: translateX(0);
}
.slide-in-left-enter, .slide-in-left-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}

  #visualization {
    background: white;
    margin: auto;
    height: 700px;
    border: 1px solid lightgray;
  } 
  .vis-timeline{
    border: none !important;
  }
  .vis-panel.vis-center{
    border-left-style: none; 
    border-right-style: none;
    border-top-style: none
  }
  .vis-item.vis-box{
    background-color: transparent !important;
    width: 23rem !important;
    height: auto;
    padding: 1rem !important;
    background-color: rgba(255, 255, 255, 1) !important;
    border-radius: 0.5rem !important;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  
    text-align: unset !important;
    white-space: unset !important; 
  }
  .vis-item .vis-item-content{
    white-space: unset !important; 
  }
  .subtext{
    font-size: 0.8em;
    line-height: 15px;
  }
  .menu span.nav{
    cursor: not-allowed;
  }
  .full-height{
    height: 100vh;
  }
  .general-overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(211, 211, 211, 1);
    z-index: 100;
  }

  .h-doc{
    height: 2.5rem;
    width: 2.5rem
  }
  .h-doc-counter{
    top: -5px;
    line-height: 1.25rem;
    font-weight: bold;
  }

  .tags_button_wrapper{
    height: 28px !important;
    width: 28px !important;
  }
  .tags_button{
    height: 28px !important;
    width: 28px !important;
    padding: .35rem !important;
    line-height: 20px !important;
    font-size: 17px !important;
}
.tags_button:hover{
    cursor: pointer;
}
  .tags_button_submit{
    height: 28px !important;
    padding: .35rem !important;
    font-size: 17px !important;
}
.tags_button_submit:hover{
    cursor: pointer;
}
.selectedItemModalCloser{
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 150;
}
</style>
