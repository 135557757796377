<template>
  <div class="antialiased bg-body text-body font-body admin-port">
    <!-- Content Begins -->
    <div class="mx-auto lg:ml-80">

      <adminUserTopNavVue/>
            
      <!-- Body begins -->
      <section class="py-8 h-full">
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="w-full md:w-1/2 lg:w-1/3 p-4">
              <router-link :to="{name: 'forms'}" class="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md assets hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <div class="flex justify-between">
                  <h5 class="mb-2 inline-block text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Forms</h5>
                  <img :src="require('@/assets/images/form.png')" class="inline-block h-8">
                </div>
                <p class="font-normal text-gray-700 dark:text-gray-400">Create, edit and update your forms. Forms are to be associated with a timeline. A form can be used to collect client's details in a timeline</p>
              </router-link>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-4">
              <router-link :to="{name: 'themes'}" href="#" class="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md assets hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <div class="flex justify-between">
                  <h5 class="mb-2 inline-block text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Themes</h5>
                  <img :src="require('@/assets/images/theme.png')" class="inline-block h-8">
                </div>
                <p class="font-normal text-gray-700 dark:text-gray-400">Select and associate a theme to a form. Your created form would be displayed in the theme you select.</p>
              </router-link>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-4">
              <a href="#" class="cursor-not-allowed block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md assets hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <div class="flex justify-between">
                  <h5 class="mb-2 inline-block text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Email</h5>
                  <img :src="require('@/assets/images/theme.png')" class="inline-block h-8">
                </div>
                <p class="font-normal text-gray-700 dark:text-gray-400">Create and edit you email template. Email templates are used to send emails throughout the application</p>
              </a>
            </div>
          </div>
        </div>
      </section>
      <!-- Body ends -->  

    </div>
    <!-- Content Starts -->
  </div>
</template>

<script>
import adminUserTopNavVue from '@/components/header/adminUserTopNav.vue'

export default {
  components:{
    adminUserTopNavVue,
  },
  methods: {

  },
  mounted(){
  }
}
</script>

<style scoped>
.assets{
  min-height: 200px;
}
</style>