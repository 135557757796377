<template>
  <div>
    <form class="w-full max-w-lg mx-auto" id="eventForm">
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
            When did this happen
          </label>
          <div class="relative">
            <select @change="addstyles(true)" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" v-model="eventLoadedForEdit.when_did_this_happen" required>
              <option v-for="(possibleValue, index) in possibleValueForWhenDidThisHappen" :key="index" :value="possibleValue">{{possibleValue}}</option>              
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
            Event Category
          </label>
          <div class="relative">
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" v-model="eventLoadedForEdit.event_category" required>
              <option v-for="(possibleValue, index) in possibleValueForEventCategory" :key="index" :value="possibleValue">{{possibleValue}}</option>              
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6" v-if="eventLoadedForEdit.when_did_this_happen && eventLoadedForEdit.when_did_this_happen != `I don't know`">
        <div class="w-full px-3" v-if="eventLoadedForEdit.when_did_this_happen === `I know the specific date`">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name" >
            Enter specific date
          </label>
          <date-picker valueType="format" v-model="eventLoadedForEdit.date" format="MM-DD-YYYY" type="date" placeholder="Select date" :inputClass="datePickerInputClasses"></date-picker>
        </div>
        <div class="w-full px-3" v-if="eventLoadedForEdit.when_did_this_happen === `I know the month and year`">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name" >
            Enter the month and year
          </label>
          <date-picker valueType="format" v-model="eventLoadedForEdit.date" format="MM-YYYY" type="month" placeholder="Select date" :inputClass="datePickerInputClasses"></date-picker>
        </div>
        <div class="w-full px-3" v-if="eventLoadedForEdit.when_did_this_happen === `I know it happened a certain time after the last event`">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name" >
            Enter the date range
          </label>
          <date-picker valueType="format" type="date" range placeholder="Select date range" v-model="eventLoadedForEdit.date" :inputClass="datePickerInputClasses"></date-picker>
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6" >
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name" >
            Event Method
          </label>
          <div class="relative">
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" v-model="eventLoadedForEdit.event_method" required>
              <option v-for="(possibleValue, index) in possibleValueForEventMethod" :key="index" :value="possibleValue">{{possibleValue}}</option>              
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>
        </div>
      </div>

      <div class="my-10">
        <h4 class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-3">
          People Involved
        </h4>
      
        <div v-for="(personInvolved, index) in eventLoadedForEdit.peopleInvolved" :key="index" class="flex flex-wrap -mx-3 mb-3">
          <div class="w-full md:w-3/6 px-3">
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Name" v-model="personInvolved.employer_name">
          </div>
          <div class="w-full md:w-2/6 px-3">
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Job and Title" v-model="personInvolved.employer_job">
          </div>
          <div class="w-full md:w-1/6 px-3">
            <div class="w-full h-full flex items-center justify-center ">
              <button  @click.prevent="removePerson(index)" type="button" class="text-red-700 border border-red-700 hover:bg-gray-400 hover:border-gray-400 hover:text-white font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center" :disabled="eventLoadedForEdit.peopleInvolved.length === 1" :class="{'cursor-not-allowed': isDisabled(index, eventLoadedForEdit.peopleInvolved.length)}">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"></path>
                </svg>
                <span class="sr-only">Delete</span>
              </button>              
            </div>
          </div>
        </div>

        <div>
          <button @click.prevent="addMorePeople()" type="button" class="float-right text-white bg-gray-600 hover:bg-gray-700 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center mr-2">
            <svg fill="none" class="w-5 h-5 mr-2 -ml-1" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6"></path>
            </svg>
            Add more
          </button>
        </div>
        <div class="clear-both"></div>
        

      </div>

      <div class="flex flex-wrap -mx-3 mb-2 my-6">
        <div class="w-full px-3 mb-6 md:mb-0">          
          <label for="message" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Describe what happened</label>
          <textarea id="message" rows="4" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" v-model="eventLoadedForEdit.eventDescription"></textarea>
        </div>
      </div>

      <div class="-mx-3 my-6 px-3">
        <h4 class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-3">
          Documents
        </h4>
        <div class="flex flex-wrap -mx-3 mb-4">
          <div class="w-full px-3 mb-6 md:mb-0">            
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  type="file" @change.prevent="selectFile(event)" id="tempFileHolder">
          </div>
        </div>

        <ul 
          class="list-disc list-inside" 
          v-if="(eventLoadedForEdit.files.old.length > 0) || (eventLoadedForEdit.files.new.length > 0)">
          <h5 class="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-1">Uploaded documents for this event</h5>
          <li v-for="(file, oldFilesIndex) in eventLoadedForEdit.files.old" :key="'old'+ oldFilesIndex" class="text-gray-900">
            {{ file.event_document_name }} <span class="text-green-500">(uploaded)</span>
            <span class="inline" @click="deleteFile(oldFilesIndex)">
              <svg class="w-5 h-5 inline hover:text-red-800 text-red-600 ml-2" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"></path>
              </svg>
              <span class="sr-only">Delete</span>
            </span> 
          </li>
          <li 
            v-for="(file, newFilesIndex) in eventLoadedForEdit.files.new" 
            :key="'new'+ newFilesIndex" 
            :class="{
              'text-gray-900': file.remark === 'uploaded',
              'text-gray-500': file.remark != 'uploaded',
            }">
            {{ file.file.name }} <span class="text-green-500">{{ (file.remark === 'uploaded') ? '(uploaded)' : '' }}</span>
            <span class="inline" @click="(file.remark === 'uploaded') ? deleteFile(newFilesIndex) : removeFile(newFilesIndex)">
              <svg class="w-5 h-5 inline hover:text-red-800 text-red-600 ml-2" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"></path>
              </svg>
              <span class="sr-only">Delete</span>
            </span> 
          </li>
        </ul>

        <div class="clear-both"></div>        
      </div>

      <button @click.prevent="submitEventForSaving()" :disabled="disableSubmitBtn" :class="{'cursor-not-allowed': disableSubmitBtn}" type="submit" class="inline-flex mr-3 items-center mt-3 px-3 py-2 text-sm font-medium text-center text-white bg-customb-500 rounded-lg hover:bg-gray-800">
        <svg fill="none" class="w-5 h-5 mr-2 -ml-1" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="saveEvent">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"></path>
        </svg>
        Save Event
      </button>
      <button v-if="parent && parent == 'viz'" @click.prevent="backToViz()" class="inline-flex mr-3 items-center mt-3 px-3 py-2 text-sm font-medium text-center text-white bg-gray-500 rounded-lg hover:bg-gray-800">
        <svg fill="none" class="w-5 h-5 mr-2 -ml-1" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z"></path>
        </svg>
        Cancel
      </button>
    </form>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { AlphaNum } from 'unique-id-key';
import { adminEventsService } from '@/services';

export default {
    data(){
        return {
            datePickerInputClasses: ['appearance-none','block','w-full','bg-gray-200','text-gray-700','border','border-gray-200','rounded','py-3','px-4', 'leading-tight', 'h-12'],
            disableSubmitBtn: false,
            possibleValueForWhenDidThisHappen: [
                "I know the specific date",
                "I know the month and year",
                "I know it happened a certain time after the last event",
                "I don't know"
            ],
            possibleValueForEventCategory: [
                "Notice of Need for Leave",
                "Notice of Disability",
                "Complaint to Supervisor or above",
                "Harassment/Retaliation",
                "Termination",
                "Demotion, Change in Hours, or Pay",
                "Denied Leave or Accomodation",
                "Discriminatory Conduct",
                "Requested/Took Leave",
                "Negative Evaluation/Increased Scrutiny",
                "Interactive Process Meeting",
                "Start Date of Leave*",
                "Return Date from Leave*",
                "None of the above"
            ],
            possibleValueForEventMethod: [
                "In Person",
                "Email",
                "Text Message",
                "Phone Call",
                "Other"
            ],
            eventLoadedForEdit: {
                when_did_this_happen: '',
                event_category: '',
                date: '',
                event_method: '',
                peopleInvolved: [

                ],
                eventDescription: '',
                files: {
                old: [],
                new: []
                }
            }
        }
    },
    props: {
        event: Object,
        timelineDetails: Object,
        eventIndex: Number,
        parent: String
    },
    components: { 
        DatePicker
    },
    methods: {
        backToViz(){
            this.$emit('go-back-to-viz')
        },
        async submitEventForSaving(){
            const { when_did_this_happen, event_category, date, event_method, peopleInvolved, eventDescription, files } = this.eventLoadedForEdit

            this.event.event_category = event_category
            this.event.event_method= event_method

            let newPeople = []

            if(peopleInvolved.length > 0){
                peopleInvolved.forEach((elem)=>{
                newPeople.push(elem)
              })
            }

            this.event.event_employer = newPeople
            this.event.event_description = eventDescription
            this.event.event_happen = date

            let newDocuments = []
        
            files.old.forEach(elem => {
                newDocuments.push(elem)
            })
            files.new.forEach(elem => {
                if(elem.remark === "uploaded"){
                let format = {
                    event_document_name: elem.file.name,
                    event_document_url: elem.url
                }
                newDocuments.push(format)
                }
            })

            this.event.event_documents = newDocuments
            let eventEditedObj = {
                event: this.event, 
                index: this.eventIndex
            }

            this.$emit('done-with-editing-events', eventEditedObj)
        
        },
        selectFile(){
            let fileSelected = (event.target.files.length > 0) ? event.target.files[0] : null
            let isFileAlreadyUploaded = null
            let fileToBeUploadObj = null
            
            if(!fileSelected){
                return
            }

            isFileAlreadyUploaded = this.eventLoadedForEdit.files.new.find((elem) => {
                return elem.file.name === fileSelected.name
            })

            if (isFileAlreadyUploaded) {
                this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'This file has already been added!',
                });
                document.querySelector('#tempFileHolder').value = ''
                return
            }

            fileToBeUploadObj = {
                id: AlphaNum(20),
                file: fileSelected,
                url: '',
                remark: ''
            }

            if(this.eventLoadedForEdit.files.new.push(fileToBeUploadObj)){
                this.uploadDocument(fileToBeUploadObj.id)
                document.querySelector('#tempFileHolder').value = ''
            }        
        },
        deleteFile(index){

        },
        removeFile(index){
            this.eventLoadedForEdit.files.new.splice(index, 1)      
        },
        addstyles(delay){
        
            if (delay) {
                setTimeout(()=>{
                let allFormInputElem = document.querySelector('#eventForm input')
                    
                if(allFormInputElem){
                    allFormInputElem.classList.add('h-12')
                }
                }, 0.1)
            }else{
                let allFormInputElem = document.querySelector('#eventForm input')
                
                if(allFormInputElem){
                allFormInputElem.classList.add('h-12')
                }
            }
        
        },
        isDisabled(elemIndex, arrLength){
            if (elemIndex === 0 && arrLength === 1) {
                return true
            }else{
                return false
            }
        },
        addMorePeople(){
            this.eventLoadedForEdit.peopleInvolved.push({
                employer_name: '',
                employer_job: ''
            })
        },
        removePerson(index){
            if (this.eventLoadedForEdit.peopleInvolved.length > 1) {        
                this.eventLoadedForEdit.peopleInvolved.splice(index, 1)
            }
        },
        async uploadDocument(fileId){
            let fileObj = null
            let formData = null
            let timelineToken = null

            this.disableSubmitBtn = true
            fileObj = this.eventLoadedForEdit.files.new.find(elem => {
                return elem.id === fileId
            })

            if(!fileObj){
                return
            }

            formData = new FormData();
            timelineToken = (this.timelineDetails.token) ? this.timelineDetails.token : null

            formData.append("file", fileObj.file)
            
            for (const value of formData.values()) {
                console.log(value);
            }
            let upload_res = await adminEventsService.submitDocument(formData, timelineToken)

            console.log(upload_res)

            if(!upload_res.type == 'success'){
                // Unsuccessfull
            }

            this.eventLoadedForEdit.files.new.forEach((elem, index) => {
                if (elem.id === fileId) {
                elem.url = upload_res.data.url
                elem.remark = 'uploaded'
                
                let uploadedFile = {
                    event_document_name: elem.file.name,
                    event_document_url: elem.url
                }

                // this.eventLoadedForEdit.files.old.push(uploadedFile)
                // this.eventLoadedForEdit.files.new.splice(index, 1)
                }
                
            })

            this.disableSubmitBtn = false
        }
    },
    mounted(){
        this.addstyles(true)
        
        if(this.event){
            const { event_happen, event_category, event_method, event_employer, event_description, event_documents } = this.event
            
            this.eventLoadedForEdit.event_category = event_category
            this.eventLoadedForEdit.event_method = event_method

            if(event_employer){
              if(event_employer.length == 0){
                this.eventLoadedForEdit.peopleInvolved.push({
                  employer_name: '',
                  employer_job: ''
                })
              } else if(event_employer.length > 0){
                  event_employer.forEach((elem)=>{
                    this.eventLoadedForEdit.peopleInvolved.push(elem)
                  })
              }
            }else{
              this.eventLoadedForEdit.peopleInvolved.push({
                employer_name: '',
                employer_job: ''
              })
            }

            if(event_happen){
                try {
                let elem = event_happen.split('-')
                elem = (elem) ? elem.length : 0

                switch (elem) {
                    case 3:
                    this.eventLoadedForEdit.when_did_this_happen = 'I know the specific date'
                    this.eventLoadedForEdit.date = event_happen
                    break;
                    case 2:
                    this.eventLoadedForEdit.when_did_this_happen = 'I know the month and year'
                    this.eventLoadedForEdit.date = event_happen
                    break;
                
                    default:
                    break;
                }
                } catch (error) {
                
                }
            }

            this.eventLoadedForEdit.eventDescription = event_description
            this.eventLoadedForEdit.files.old = (event_documents) ? event_documents : []

        }

        if (this.eventLoadedForEdit.peopleInvolved.length == 0) {
            addMorePeople()
        }
        
    }

}
</script>

<style>

</style>