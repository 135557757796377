import { authServices } from '@/services';
import router from '@/router';

const user = JSON.parse(localStorage.getItem('user'));

const state = {
    ...(user) 
        ? { status: { loggedIn: true }, user } 
        : { status: {}, user: null }
}

const getters = {
    getUser:  (state)=>{
        return state.user
    }
}

const actions = {
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', email );
    
        authServices.login(email, password)
            .then(
                res => {
                    if(res.type === 'success'){
                        commit('loginSuccess', res.user);
                        if (res.user.user_type == "client") {                            
                            router.push({name: 'userDashboard'})
                        } else if (res.user.user_type == "admin") {                            
                            router.push({name: 'adminDashboard'})
                        }
                        dispatch('changeNav', 'admin', { root: true })
                    }else if(res.type === 'error'){
                        commit('loginFailure');
                        let alertObj = res
                        dispatch('alertModule/error', alertObj, { root: true });
                    }
                },
                error => {
                }
            )
            .catch((err) => {
                commit('loginFailure');
                let alertObj = { 
                    location: 'login' , 
                    message: 'Sorry, we could not process your request at the moment. Try again or contact support' 
                  }
                dispatch('alertModule/error', alertObj, { root: true });
            });
    },
    logout({ commit }) {
        authServices.logout();
        commit('logout');
    }
}

const mutations = {
    loginRequest(state, email) {
        state.status = { loggingIn: true };
        state.user = email;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
}

export default {
    namespaced: true,
    state, 
    getters,
    actions,
    mutations
}