import { authHeader } from '@/helper';
import { URL } from '@/helper';
import { authServices } from '@/services'
import axios from 'axios'

export const adminDashboardService = {
    getAnalytics, 
    getVisitorsData,
    getClientAnalyticsData
};

async function getAnalytics() {
    return await axios({
            method: 'post',
            url: URL+'/api/get-analytics-data',            
            headers: authHeader(),
            data: {
                ownerId: authServices.user().id, 
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let forms = response.data.data
                if (forms) {
                    var res = {
                        type: 'success',
                        location: 'formView', 
                        data: forms 
                    }
                    return res
                }
            }
        })
        .catch(function (err) {
            if(err.response){
                if (err.response.data.message === "Expired token") {
                    authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}

async function getClientAnalyticsData(username) {
    return await axios({
            method: 'post',
            url: URL+'/api/get-client-analytics-data',            
            headers: authHeader(),
            data: {
                username: username, 
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let resData = response.data.data
                if (resData) {
                    var res = {
                        type: 'success',
                        location: 'homeView', 
                        data: resData 
                    }
                    return res
                }
            }
        })
        .catch(function (err) {
            if(err.response){
                if (err.response.data.message === "Expired token") {
                    authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}

async function getVisitorsData(chartType) {
    return await axios({
            method: 'post',
            url: URL+'/api/get-vistors-data',            
            headers: authHeader(),
            data: {
                chartType: chartType, 
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let forms = response.data.data
                if (forms) {
                    var res = {
                        type: 'success',
                        location: 'formView', 
                        data: forms 
                    }
                    return res
                }
            }
        })
        .catch(function (err) {
            if(err.response){
                if (err.response.data.message === "Expired token") {
                    authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}

function prepareError(msg){
    var res = {
        type: 'error',
        location: 'timelineView', 
        message: null 
    }

    res.message = (msg)
        ? msg
        : 'Sorry, we are experiencing some chanllenges. Please contact support'

    return res
}
