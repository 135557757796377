const state = {
    activeConditionLoadedForEditor: null,
    activeFormName: ''
}

const getters = {
    getActiveCondition: (state) => {
        return state.activeConditionLoadedForEditor
    },
    getActiveFormName: (state) => {
        return state.activeFormName
    }
}

const actions = {
    setActiveCondition: ({commit}, activeCondition) => {
        commit('setActiveConditionLoadedForEditor', activeCondition);
    },
    setActiveFormName: ({commit}, formName) => {
        commit('setFormName', formName);
    }
}

const mutations = {
    setActiveConditionLoadedForEditor: (state, activeCondition) => {
        state.activeConditionLoadedForEditor = activeCondition
    },
    setFormName: (state, formName) => {
        state.activeFormName = formName
    }
}

export default {
    namespaced: true,
    state, 
    getters,
    actions,
    mutations
}