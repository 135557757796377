<template>
  <!-- Content Header Begins -->
  <section class="py-8">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap items-stretch -m-4">
          <div class="w-full lg:w-12/12 p-4">
            <div class="bg-white shadow rounded overflow-hidden">
              <div class="p-4">
                <div class="flex justify-between items-center mb-6">
                  <a @click.prevent="goBack()" class="inline-block py-1 px-2 bg-customb-500 rounded-full text-xs text-white transition duration-200" href="#">&lt;&lt; Go back</a>          
                </div>
                <div class="">
                  <h3 class="font-medium text-md" v-if="headerData.claims">
                    Timeline Claim: 
                    {{headerData.claims.join(', ')}}
                  </h3>
                  <h3 class="font-medium text-md" v-else>
                    Timeline
                  </h3>
                </div>
              </div>
              <!-- <div class="px-4 py-2 flex items-center justify-between bg-lightGray-50">           
                <div>
                  <span class="block mb-2 text-sm text-gray-500 font-medium tracking-normal">
                    A claim of type <span class="italic underline decoration-dotted  font-bold">{{(headerData.claims) ? headerData.claims.join(', ') : ' '}}</span> requires the following event type(s) before your application can be successfully processed</span>
                    <div>
                      <span class="inline-block py-1 px-3 mr-1 my-1 rounded-full bg-green-50 text-xs text-gray-500">20 September 2021</span>
                      <span class="inline-block py-1 px-3 mx-1 my-1 rounded-full bg-green-50 text-xs text-gray-500">20 September 2021</span>
                      <span class="inline-block py-1 px-3 mx-1 my-1 rounded-full bg-orange-50 text-xs text-red-500">20 September 2021</span>
                      <span class="inline-block py-1 px-3 mx-1 my-1 rounded-full bg-orange-50 text-xs text-red-500">20 September 2021</span>
                    </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
  </section>
  <!-- Content Header ends -->
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'timelineHeader',
  data(){
    return {
      claims: null,
      events: [
        'View User',
        'Email User',
        'Delete Timeline',
      ],
      showUserDropdown: false
     }
  },
  props: {
    headerData: Object,
  },
  components:{

  },
  methods:{
    goBack(){
      this.$router.back()
    },

    async trigger_archiveTimeline(){
      this.$emit('sent-timeline-to-archive')
    },
    interactWithTimeline(event){

    }
  },
  mounted(){
  },
  computed: {
    ...mapGetters(['getAllDropdownToShow', 'getFullScreenDisplayStatus']),
  }
}
</script>

<style>
/* Animations */
.fade-enter-active, .fade-leave-active {
  transition: opacity .1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
