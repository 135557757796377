<template>
    <div class="antialiased bg-body text-body font-body admin-port">    
      <!-- Content Begins -->
  
      <div class="mx-auto" :class="{'lg:ml-80': !getFullScreenDisplayStatus}">
        <adminUserTopNavVue/> 
        
        <!-- Content Header Begins -->
        <section class="py-8">
            <div class="container px-4 mx-auto">
                <div class="p-6 bg-white  rounded shadow">                
                    <div class="flex justify-between items-center my-3">
                        <a @click.prevent="goBack()" class="inline-block py-1 px-2 bg-customb-500 rounded-full text-xs text-white transition duration-200" href="#">&lt;&lt; Go back</a>          
                    </div>
                    <div class="lg:flex justify-between ">
                        <div class="">
                            <p class="text-sm text-gray-500">Add conditional logic to your form.</p>
                        </div>
                        <div class="w-full md:w-auto my-6 md:my-0 flex items-center">
                        <div class="relative inline-block">
                            <!-- Dropdown toggle button -->
                            <button @click="isOpen = !isOpen" class="relative z-10 block p-2 text-gray-700 bg-white border border-purple-900 rounded-md focus:border-purple-300 focus:ring-opacity-40 focus:ring-purple-200 focus:ring  focus:outline-none">
                                <svg class="w-5 h-5 text-gray-800 dark:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </button>
    
                            <!-- Dropdown menu -->
                            <div v-show="isOpen" 
                                @click="isOpen = false"
                                x-transition:enter="transition ease-out duration-100"
                                x-transition:enter-start="opacity-0 scale-90"
                                x-transition:enter-end="opacity-100 scale-100"
                                x-transition:leave="transition ease-in duration-100"
                                x-transition:leave-start="opacity-100 scale-100"
                                x-transition:leave-end="opacity-0 scale-90" 
                                class="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl dark:bg-gray-800"
                            >
                                <router-link :to="{name: 'form', params: {form_id: form_id}}" class="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100 hover:cursor-pointer"> Edit form </router-link>
                                <span @click.prevent="saveConditional()" href="#" class="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100 hover:cursor-pointer"> Save Conditions </span>
                            </div>
                        </div>
                        </div>
    
                    </div>
                </div>

            </div>
        </section>
        <!-- Content Header ends -->
  
        <section class="py-4">
          <div class="container px-4 mx-auto">
            <div class="py-4 bg-white shadow rounded form-wrapper relative">
                <div v-if="isSaving" class="general-overlay"></div>
                
                <div v-if="(activeComponent == 'ConditionsVue') && formConditions.length">
                    <addNewConditionalTriggerVue @addNew="addNewCondition()"  />
                    <ConditionsVue v-for="(cond, index) in formConditions" :key="index" :currentCondition="cond" @editCondition="editCondition(cond)" @deleteCondition="deleteCondition(cond)"/>
                </div>
                
                <div v-if="(activeComponent == 'conditionalEditiorBlockVue')">
                    <conditionalEditiorBlockVue :condition="getActiveCondition" :fields="formFields" @conditionSave="newConditionSaved()"/>
                </div>
                
                <div v-if="(formConditions.length == 0) && ( activeComponent != 'conditionalEditiorBlockVue')" class="absolute top-o left-0 h-full w-full flex justify-center items-center">                    
                    <div class="w-full max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <div v-if="formFields.length >= 2" class="flex flex-col items-center py-10">
                            <div class="mb-3 w-24 h-24 rounded-full shadow-lg flex justify-center items-center">
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" class="w-12 h-12"  viewBox="0 0 60.364 60.364" style="enable-background:new 0 0 60.364 60.364;" xml:space="preserve">
                                <g>
                                    <path style="fill:#374151;" d="M54.454,23.18l-18.609-0.002L35.844,5.91C35.845,2.646,33.198,0,29.934,0c-3.263,0-5.909,2.646-5.909,5.91v17.269
                                        L5.91,23.178C2.646,23.179,0,25.825,0,29.088c0.002,3.264,2.646,5.909,5.91,5.909h18.115v19.457c0,3.267,2.646,5.91,5.91,5.91
                                        c3.264,0,5.909-2.646,5.91-5.908V34.997h18.611c3.262,0,5.908-2.645,5.908-5.907C60.367,25.824,57.718,23.178,54.454,23.18z"/>
                                </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                                </svg>
                            </div>

                            <h5 class="my-1 text-xl font-medium text-gray-500">No conditions</h5>
                            <div @click.prevent="addNewCondition()" class="flex mt-4 space-x-3 md:mt-6">
                                <button href="#" class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 ">Add new</button>
                            </div>
                        </div>
                        <div v-else class="flex flex-col items-center py-10 justify-center">
                            <h5 class="my-1 text-xl font-medium text-gray-500 w-80 text-center">This form does not have sufficient fields</h5>
                            <div @click.prevent="goBack()" class="flex mt-4 space-x-3 md:mt-6">
                                <button href="#" class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 ">Add more Fields</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
          </div>
        </section>
      </div>
  
      <!-- Content Ends -->
    </div>
</template>
  

<script>
import adminUserTopNavVue from '@/components/header/adminUserTopNav.vue'
import conditionalEditiorBlockVue from '@/components/forms/conditionalEditiorBlock.vue'
import ConditionsVue from '@/components/forms/aCondition.vue'
import addNewConditionalTriggerVue from '@/components/forms/addNewConditionalTrigger.vue'

import unique_id from 'unique-id-key'
import { adminFormService } from '@/services'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'formConditionalEditior',
    components:{
        adminUserTopNavVue,
        conditionalEditiorBlockVue,
        ConditionsVue,
        addNewConditionalTriggerVue
    },
    data(){
        return {
            form_id: null,
            isSaving: false,
            isOpen: false,
            activeComponent: 'ConditionsVue',
            error: null,
            form: null,
            formConditions: [],
            formFields: [],
        }
    },
    computed: {
        ...mapGetters('formModule', ['getActiveCondition'])
    },
    methods: {
        ...mapActions('formModule', ['setActiveCondition']),
        addNewCondition(){
            let conditionID = unique_id.AlphaNum(10),
                conditionSkeleton = {
                    conditionID: conditionID,
                    if_condition: {
                        fieldLabel: '',
                        cid: ''
                    },
                    state_condition: '',
                    value_condition: '',
                    do_condition: '',
                    on_condition: {
                        fieldLabel: '',
                        cid: ''
                    }
                }

                this.setActiveCondition(conditionSkeleton)
                this.activeComponent = 'conditionalEditiorBlockVue'

        },
        checkForFormID(){
            if(this.$route.params.form_id){
                this.form_id = this.$route.params.form_id
            }else{
                this.$router.push({name: 'forms'})
            }
        },
        goBack(){
            if(this.form_id){
                this.$router.push({name: 'form', params: {form_id: this.form_id}})
            }
        },
        newConditionSaved(){
            const condition = this.getActiveCondition
            // save condition to form
            if(this.formConditions.length == 0){
                this.formConditions.push(condition)
            }else{
                let { conditionID, if_condition, state_condition, value_condition, do_condition, on_condition } = condition

                let editedCondition = this.formConditions.find((elem) => {
                    return elem.conditionID == conditionID
                })
                
                if(editedCondition){
                    editedCondition.if_condition = if_condition
                    editedCondition.state_condition = state_condition
                    editedCondition.value_condition = value_condition
                    editedCondition.do_condition = do_condition
                    editedCondition.on_condition = on_condition
                }else{
                    this.formConditions.push(condition)
                }

            }

            this.activeComponent = 'ConditionsVue'
        },
        editCondition(condition){
            this.setActiveCondition(condition)
            this.activeComponent = 'conditionalEditiorBlockVue'
        },
        deleteCondition(condition){
            if(this.formConditions.length){            

                let index = this.formConditions.findIndex((elem)=>{
                    return elem.conditionID == condition.conditionID
                })
                
                if(index > -1){
                    this.formConditions.splice(index, 1); 
                }

            }
        },
        async getForm(){
            let self = this
            
            await adminFormService.getFormByFormID(self.form_id)
            .then((res)=>{
                if(res.type === 'success'){
                    let form = res.data
                    let conditions = form.form_conditions
                    let schema = form.form_schema
                                        
                    conditions = (JSON.parse(conditions).conditions) ? JSON.parse(conditions).conditions : []
                    schema = (JSON.parse(schema).fields) ? JSON.parse(schema).fields : []
                    
                    self.formConditions = conditions
                    self.formFields = schema
                    self.form = form

                }else if(res.type === 'error'){
                    
                }
            })
        },
        async saveConditional(){
            let form = this.form
            if(form){
                this.isSaving = true
                let self = this
                
                let payload = {
                    conditions: self.formConditions
                }
                
                form.form_conditions = JSON.stringify(payload)
            
                await adminFormService.saveFormChanges(self.form)
                .then((res)=>{
                    if(res.type === 'success'){
                    let form = res.data
                    let conditions = form.form_conditions
                    let schema = form.form_schema
                                        
                    conditions = (JSON.parse(conditions).conditions) ? JSON.parse(conditions).conditions : []
                    schema = (JSON.parse(schema).fields) ? JSON.parse(schema).fields : []
                    
                    self.formConditions = conditions
                    self.formFields = schema
                    self.form = form

                    self.isSaving = false
                    
                    }else if(res.type === 'error'){
                    self.isSaving = false              
                    }
                })
                .catch(()=>{
                    self.isSaving = false
                })
            }
        }
    },

    mounted(){
        this.checkForFormID()
        this.getForm()
    }
}
</script>

<style>
.form-wrapper{
  min-height: 70vh;
}
.conditionMenu{
    display: none !important;
}
.conditionMenuInitiator:hover + .conditionMenu{
    display: flex !important;
}
.conditionMenu:hover{
    display: flex !important;
}
</style>