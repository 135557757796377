import Vue from 'vue'
import Vuex from 'vuex'

import userModule from '@/store/modules/user.module'
import alertModule from '@/store/modules/alert.module'
import formModule from '@/store/modules/form.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nav: 'main',
    accessTimelineModal: false,
    allDropdownToShow: [],
    fullScreen: false,
    assetDropdownStatus: false,
    showHeaderOnPublicPages: true
  },
  getters: {
    getNavType: (state)=>{
      return state.nav
    },
    getAccessTimelineModalStatus: (state)=>{
      return state.accessTimelineModal
    },
    getAllDropdownToShow: (state) => {
      return state.allDropdownToShow
    },
    getFullScreenDisplayStatus: (state)=>{
      return state.fullScreen
    },
    getAssetDropdownStatus: (state)=>{
      return state.assetDropdownStatus
    },
    getStatus_HeaderOnPublicPages: (state)=>{
      return state.showHeaderOnPublicPages
    }
  },
  actions: {
    changeNav: ({ commit }, navType)=>{
      /**
       * Nav Type can be either of "main" or "admin"
       */
      if(navType){
        commit('changeNavType', navType)
      }
    },
    toggleAccessTimelineModal({ commit }){
      commit('flipAccessTimelineModalStatus')
    },
    setDropdownToShow({ commit }, data){
      commit('setAllDropdownToShow', data)
    },
    toggleFullScreenStatus({ commit }, data){
      commit('setFullScreenDisplayStatus', data)
    },
    toggleAssetDropdownStatus({commit}, data){
      commit('toggleAssetDropdownStatus', data)
    },
    toggleStatus_HeaderOnPublicPages({commit}, data){
      commit('toggleStatus_HeaderOnPublicPages', data)
    }

  },
  mutations: {
    changeNavType: (state, data)=>{
      state.nav = data
    },    
    flipAccessTimelineModalStatus: (state, data)=>{
      state.accessTimelineModal = !state.accessTimelineModal
    },    
    setAllDropdownToShow: (state, data)=>{
      state.allDropdownToShow = data
    },    
    setFullScreenDisplayStatus: (state, data)=>{
      state.fullScreen = data
    },
    toggleAssetDropdownStatus: (state, data = '')=>{
      if(data == 'toggle'){
        state.assetDropdownStatus = !state.assetDropdownStatus
      }else if(data == 'off'){
        state.assetDropdownStatus = false
      }
    },       
    toggleStatus_HeaderOnPublicPages: (state, data)=>{
        state.showHeaderOnPublicPages = data
    }       
  },
  modules: {
    userModule,
    alertModule,
    formModule
  }
})
