import { adminDashboardService } from '@/services'
import userModule from '@/store/modules/user.module';

export const dashboardHomeMixins = {
    data(){
        return {
          clients: null,
          documents: null,
          lawyers: null,
          recentTimelines: [],
          timelines: null,
          pageHandler:{
            data: [],
            page: 1,
            dataPerPage: 10,
            pages: [],
            lastPage: false,
            loadingMoredata: false,
            isnew: null
          },
        }
    },
    methods: {
        gotoTimeline(timeline){
          this.$router.push({name: 'timeline', params: {token: timeline.token}})
        },
        async getClientAnalyticsData(){
            let self = this
          
            await adminDashboardService.getClientAnalyticsData(userModule.state.user.email)
                .then((res)=>{
                    if(res.type === 'success'){
                        console.log(res.data);
                        const { documents, recentTimelines, timelines} = res.data
                    
                        self.documents = documents,
                        self.recentTimelines = recentTimelines,
                        self.timelines = timelines
            
                    }else if(res.type === 'error'){
                        
                    }
                })
        },
        async getAnalyticsData(){
          let self = this
          
            await adminDashboardService.getAnalytics()
                .then((res)=>{
                    if(res.type === 'success'){
                        console.log(res.data);
                        const {clients, documents, lawyers, recentTimelines, timelines} = res.data
                    
                        self.clients = clients,
                        self.documents = documents,
                        self.lawyers = lawyers,
                        self.recentTimelines = recentTimelines,
                        self.timelines = timelines
            
                    }else if(res.type === 'error'){
                        
                    }
                })
        },
        paginate (timelines, isnew = false) {
            if (this.pageHandler.isnew) {
                // result from a search
                this.pageHandler.data = []
                this.pageHandler.page = 1,
                this.pageHandler.dataPerPage = 10,
                this.pageHandler.pages = []
            }
        
            let page = this.pageHandler.page;
            let perPage = this.pageHandler.dataPerPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            
            let dataWithinRange = timelines.slice(from, to);
        
        
            dataWithinRange.forEach(elem => {
                this.pageHandler.data.push(elem)
            })
        
            return  this.pageHandler.data;
        },
        loadMoredata(){
            this.pageHandler.loadingMoredata = true
            this.pageHandler.isnew = false
            this.pageHandler.page ++
            this.pageHandler.loadingMoredata = false
        },    
    },
    computed: {
        displayedTimelines () {
          if (this.recentTimelines) {
            return this.paginate(this.recentTimelines);
          }
        },
        disableNextButton(){
          if(this.pageHandler.data.length === this.recentTimelines.length){
            return true
          }
        }
    },
}