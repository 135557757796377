import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginView from '../views/LoginView.vue'
import FormEntry from '../views/FormEntry.vue'

// Admin
import AdminView from '../views/AdminView.vue'
import AdminDashboardView from '../views/admin/DashboardView.vue'
import UsersView from '../views/admin/UserView.vue'
import SupportView from '../views/admin/SupportView.vue'
import SettingsView from '../views/admin/SettingsView.vue'

import AdminTimelineView from '../views/admin/TimelineView.vue'
import TimelinesView from '../views/admin/timeline_views/timelines.vue'
import TimelineView from '../views/admin/timeline_views/timeline.vue'

import AdminFormView from '../views/admin/FormView.vue'
import FormsView from '../views/admin/assets/form_views/forms.vue'
import FormView from '../views/admin/assets/form_views/form.vue'
import formConditionalEditor from '../views/admin/assets/form_views/formConditionalEditor.vue'

import AdminThemeView from '../views/admin/ThemeView.vue'
import ThemesView from '../views/admin/assets/themes_views/themes.vue'

import adminFormBuilder from '../views/admin/adminFormsView.vue'
import adminAssetsView from '../views/admin/adminAssetsView.vue'

// User
import UserView from '../views/ClientView.vue'
import UserDashboardView from '../views/client/DashboardView.vue'

import UserTimelineView from '../views/client/TimelineView.vue'
import UserTimelinesView from '../views/client/timeline_views/timelines.vue'
import UserSingleTimelineView from '../views/client/timeline_views/timeline.vue'
import UserSettings from '../views/client/SettingsView.vue'
import UserSupport from '../views/client/SupportView.vue'


// Public Timeline View
import PublicTimelineView from '../views/timelinePortal/index.vue'
import PublicTimelinesView from '../views/timelinePortal/timelines_public.vue'
import PublicSingleTimelineView from '../views/timelinePortal/timeline_public.vue'


import store from '@/store'
import userModule from '@/store/modules/user.module';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/begin',
    name: 'formEntryPoint',
    component: FormEntry,
  },
  {
    path: '/timeline-portal',
    name: 'timelinePortal',
    component: PublicTimelineView,
    children: [
      {
        path: '/',
        name: 'publicTimelines',
        component: PublicTimelinesView
      },
      {
        path: '/public-timeline/:token',
        name: 'publicTimeline',
        component: PublicSingleTimelineView
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
  },

  // ADMIN
  {
    path: '/admin',
    component: AdminView,
    children: [
      {
        path: 'dashboard',
        name: 'adminDashboard',
        component: AdminDashboardView
      },
      {
        path: 'users',
        name: 'adminUsers',
        component: UsersView
      },
      {
        path: 'assets',
        name: 'adminAssets',
        component: adminAssetsView
      },
      {
        path: 'builder',
        name: 'adminFormBuilder',
        component: adminFormBuilder
      },
      {
        path: 'support',
        name: 'support',
        component: SupportView
      },
      {
        path: 'settings',
        name: 'adminSettings',
        component: SettingsView
      },

      // Timelines
      {
        path: 'timelines',
        name: 'adminTimelines',
        component: AdminTimelineView,
        children: [
          {
            path: '/',
            name: 'timelines',
            component: TimelinesView
          },
          {
            path: '/timeline/:token',
            name: 'timeline',
            component: TimelineView
          }
        ]
      },
      
      // Forms
      {
        path: 'forms',
        name: 'adminForms',
        component: AdminFormView,
        children: [
          {
            path: '/',
            name: 'forms',
            component: FormsView
          },
          {
            path: '/form/:form_id',
            name: 'form',
            component: FormView
          },
          {
            path: '/form-conitionals/:form_id',
            name: 'formConditionals',
            component: formConditionalEditor
          }
        ]
      },
      
      // Themes
      {
        path: 'theme',
        name: 'adminThemes',
        component: AdminThemeView,
        children: [
          {
            path: '/',
            name: 'themes',
            component: ThemesView
          }
        ]
      }
    ]
  },

  // CLIENT
  {
    path: '/client-portal',
    component: UserView,
    children: [
      {
        path: 'dashboard',
        name: 'userDashboard',
        component: UserDashboardView
      },
      {
        path: 'timelines',
        name: 'userTimelines',
        component: UserTimelineView,
        children: [
          {
            path: '/',
            name: 'client-timelines',
            component: UserTimelinesView
          },
          {
            path: '/timeline/:token',
            name: 'client-timeline',
            component: UserSingleTimelineView
          }
        ]
      },    
      {
        path: 'support',
        name: 'UserSupport',
        component: UserSupport
      },
      {
        path: 'settings',
        name: 'UserSettings',
        component: UserSettings
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  strict: true
})

export default router

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    'home', 
    'login',
    'formEntryPoint',
    'timelinePortal',
    'publicTimelines',
    'publicTimeline'
  ];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('user');

  if (to.name !== 'adminFormBuilder') {
    store.dispatch('toggleAssetDropdownStatus', 'off')
  }
  
  if (authRequired && !loggedIn) {
    return next({name: 'login'});
  }

  if(to.fullPath.includes('admin')){
    if (userModule.state.user.user_type !== "admin" ) {      
      if(from.name){
        return next({name: from.name})
      }else{
        return next({name: 'login'})
      }
    }
  }
  
  if(to.name === 'NotFound'){
    if (userModule.state.user.user_type === "admin" ) {      
      return next({name: 'adminDashboard'})
    }
  }

  if(to.name === 'formEntryPoint'){
    const {formName} = to.query
    
    if(!formName){
      return next({name: 'home'})
    }

    // remove headers
    store.dispatch('toggleStatus_HeaderOnPublicPages', false)
  }
  
  if(to.name === 'publicTimelines'){
    store.dispatch('toggleStatus_HeaderOnPublicPages', true)
    const {email} = to.query
    console.log(to.query)
  
    if(!email){
      return next({name: 'home'})
    }

  }
  
  // Handle auto fullscreen display
  // const pagesRequiringFullscreen = [
  //   'timeline'
  // ];

  // if(pagesRequiringFullscreen.includes(to.name)){
  //   store.dispatch('toggleFullScreenStatus', true)
  // }else{
  //   store.dispatch('toggleFullScreenStatus', false)
  // }

  next();
})
