import { authHeader } from '@/helper';
import { URL } from '@/helper';
import { authServices } from '@/services'
import axios from 'axios'


export const adminTimlineService = {
    getAllTimelines,
    getEventsByToken,
    saveEventsByToken,
    archiveTimelineByToken,
    downloadTimelineByToken,
    getUserTimelines,
    startNewTimeline
};

async function getEventsByToken(token, ispublic = false) {
    let url = (ispublic) ? URL+'/api/public_get_events' : URL+'/api/get_events',
        auth =  (ispublic) ? '' : authHeader()

        

    return await axios({
            method: 'post',
            url: url,            
            headers: auth,
            data: {
                timeline_token: token
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let events = response.data.data
                if (events) {
                    var res = {
                        type: 'success',
                        location: 'userView', 
                        data: events 
                    }
                    return res
                }
            }
        })
        .catch(async function (err) {
            if(err.response){
                if (err.response.data.message == "Expired token") {
                    await authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}
async function downloadTimelineByToken(token) {
    return await axios({
            method: 'post',
            url: URL+'/api/download_timeline',            
            headers: authHeader(),
            data: {
                timeline_token: token,
                timelineOwner: 'jeneece'
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let events = response.data.data
                if (events) {
                    var res = {
                        type: 'success',
                        location: 'userView', 
                        data: events 
                    }
                    return res
                }
            }
        })
        .catch(async function (err) {
            if(err.response){
                if (err.response.data.message == "Expired token") {
                    await authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}
async function archiveTimelineByToken(token) {
    return await axios({
            method: 'post',
            url: URL+'/api/archive_timeline',            
            headers: authHeader(),
            data: {
                timeline_token: token
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let events = response.data.data
                if (events) {
                    var res = {
                        type: 'success',
                        location: 'userView', 
                        data: events 
                    }
                    return res
                }
            }
        })
        .catch(async function (err) {
            if(err.response){
                if (err.response.data.message == "Expired token") {
                    await authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}
async function getUserTimelines(user_email_username, ispublic = false) {
    let url = (ispublic) ? URL+'/api/public-user-timelines' : URL+'/api/user-timelines',
        auth =  (ispublic) ? '' : authHeader()

    if(!ispublic){
        if(!user_email_username){
            await authServices.logout()
        }
    }
    return await axios({
            method: 'post',
            url: url,            
            headers: auth,
            data: {
                email: user_email_username
            }
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let timelines = response.data.data
                if (timelines) {
                    var res = {
                        type: 'success',
                        location: 'userView', 
                        data: timelines 
                    }
                    return res
                }
            }
        })
        .catch(async function (err) {
            if(err.response){
                if (err.response.data.message == "Expired token") {
                    await authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}
async function getAllTimelines() {
    return await axios({
            method: 'post',
            url: URL+'/api/timelines',            
            headers: authHeader()
          }            
        )
        .then(function (response) {
            if (response.data.status == "error") {        
                return prepareError(response.data.message)                  
            }else{
                let timelines = response.data.data
                if (timelines) {
                    var res = {
                        type: 'success',
                        location: 'userView', 
                        data: timelines 
                    }
                    return res
                }
            }
        })
        .catch(async function (err) {
            if(err.response){
                if (err.response.data.message == "Expired token") {
                    await authServices.logout()
                }
            }
            return prepareError(err.response.data.message)
        });
}
async function saveEventsByToken(events, token, ispublic = false) {
    let url = (ispublic) ? URL+'/api/public_save_events' : URL+'/api/save_events',
        auth =  (ispublic) ? '' : authHeader()

        
    return await axios({
        method: 'post',
        url: url,            
        headers: auth,
        data: {
            timeline_token: token,
            events: events
        }
      }            
    )
    .then(function (response) {
        if (response.data.status == "error") {        
            return prepareError(response.data.message)                  
        }else{
            let events = response.data.data
            if (events) {
                var res = {
                    type: 'success',
                    location: 'timelineView', 
                    data: events 
                }
                return res
            }
        }
    })
    .catch(async function (err) {
        if(err.response){
            if (err.response.data.message == "Expired token") {
                await authServices.logout()
            }
        }
        return prepareError(err.response.data.message)
    });
}
async function startNewTimeline(answers = []){
    return await axios({
        method: 'post',
        url: URL+'/api/create-timeline',            
        headers: authHeader(),
        data: {
            form_entries: answers
        }
      }            
    )
    .then(function (response) {
        if (response.data.status == "error") {        
            return prepareError(response.data.message)                  
        }else{
            let timeline = response.data.data
            if (timeline) {
                var res = {
                    type: 'success',
                    location: 'timelineView', 
                    data: timeline
                }
                return res
            }
        }
    })
    .catch(function (err) {
        if(err.response){
            if (err.response.data.message === "Expired token") {
                authServices.logout()
            }
        }
        return prepareError(err.response.data.message)
    });
}
function prepareError(msg){
    var res = {
        type: 'error',
        location: 'timelineView', 
        message: null 
    }

    res.message = (msg)
        ? msg
        : 'Sorry, we are experiencing some chanllenges. Please contact support'

    return res
}
