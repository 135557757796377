<template>  
  <div>
    <defaultVue v-if="form" :form="form"/>
  </div>
</template>

<script>
import defaultVue from '@/themes/default.vue'
import { mapActions, mapGetters } from 'vuex'
import { adminFormService } from '@/services'

export default {
  name: 'LoginView',
  components: {
    defaultVue
  },
  data(){
    return {
        email: '',
        password: '',
        disableLoginButton: false,
        formName: null,
        form: null
    }
  },
  methods: {
    ...mapActions('formModule', ['setActiveFormName']),
    ...mapActions('alertModule', [
      'clear'
    ]),
    ...mapActions(['changeNav', 'toggleAccessTimelineModal']),
    ...mapActions('userModule', [
      'login', 
      'logout'
    ]),
    initLogin(){
      this.clear()
      this.disableLoginButton = true
      
      if(this.email && this.password){
        this.login({ email: this.email, password:  this.password })
        
        setTimeout(()=>{
          this.clear()
          this.disableLoginButton = false
        }, 6000)
      }
    },
    async getForm(){
      let self = this
      
      await adminFormService.getFormByName(self.formName)
        .then((res)=>{
          if(res.type === 'success'){
            this.setActiveFormName(res.data.form_name)
            self.form = res.data
          }else if(res.type === 'error'){
            
          }
        })
    }
  },
  computed:{
    ...mapGetters(['getAccessTimelineModalStatus']),
    ...mapGetters('userModule', [
      'getUser'
    ]),
    ...mapGetters('alertModule', [
      'getAlertLocation', 
      'getAlertType', 
      'getAlertMessage'
    ])
  },
  mounted(){
    const {formName} = this.$route.query
  
    if(!formName){
      this.$router.push({name: 'home'})
    }
  
    this.formName = formName
    this.getForm()
    
  }
}
</script>

<style>
.loginButton:disabled,
.loginButton[disabled]{
  cursor: not-allowed;
  background-color: gray !important;
}
</style>
