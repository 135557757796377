<template>
  <div class="py-12 bg-white border border-gray-200 shadow-md relative">
    <button @click.prevent="cancelSelection()" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center selectedItemModalCloser" data-modal-hide="defaultModal">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        <span class="sr-only">Close modal</span>
    </button>

    <addAndEditEventFormVue :event="event" :timelineDetails="timelineDetails" @done-with-editing-events="saveEvent" :eventIndex="eventIndex"/>

  </div>
</template>

<script>
import addAndEditEventFormVue from './addAndEditEventForm.vue';


export default {
  props: {
    event: Object,
    timelineDetails: Object,
    eventIndex: Number,
    isNew: Boolean
  },
  components: { 
    addAndEditEventFormVue
   },
  methods: {
    cancelSelection(){
      this.$emit('closeEditor')
    },
    saveEvent(eventEditedObj){
      this.$emit('save-events', eventEditedObj)
    }
  },
  mounted(){
  }
}
</script>

<style scoped>
.mx-datepicker {
  width: 100% !important;
}
#saveEvent:disabled{
  background: gray;
  cursor: no-drop;
}
</style>