<template>
    <div>
        <div class="flex flex-wrap items-center py-5 px-6">
            <h3 class="w-full md:w-auto mb-4 md:mb-0 text-2xl font-bold capitalize ">{{chartTitle}} </h3>
            <div class="ml-auto inline-block py-2 px-3 border rounded text-xs text-gray-500">
            <select v-model="currentDataSet" class="pr-1" name="" id="">
                <option value="daily">Daily</option>
                <option value="monthly">Monthly</option>
            </select>
            </div>
        </div>
        <div class="relative">
            <div v-if="isLoading" class="general-overlay"></div>
            <div id="chart">
                <apexchart type="bar" height="300" :options="chartOptions" :series="series" ref="visitorsChart"></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { adminDashboardService } from '@/services'

export default {
    name: 'AdminVisitorsChart',
    components: {
        apexchart: VueApexCharts,
    },
    data(){
        return {
            isLoading: null,
            currentDataSet: 'monthly',
            chartTitle: 'Monthly Visitiors',
            series: [{
                name: 'Visitors',
                data: []
            }],
            chartOptions: {
                chart: {
                    height: 300,
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    show: true,
                },
                colors: ['#811b58'],
                xaxis: {
                    type: 'category',
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    labels: {
                        show: true,
                        hideOverlappingLabels: false,
                        showDuplicates: true,
                        trim: false
                    },
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        show: true,
                        align: 'right',
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                    },
                }
            },
          
        }
    },
    methods: {
        async prepareChart(chartType){
            if(chartType == 'monthly'){
                let data = await this.getVisitorData('monthly')
                
                let visitors = [
                    data["1"].visitors,
                    data["2"].visitors,
                    data["3"].visitors,
                    data["4"].visitors,
                    data["5"].visitors,
                    data["6"].visitors,
                    data["7"].visitors,
                    data["8"].visitors,
                    data["9"].visitors,
                    data["10"].visitors,
                    data["11"].visitors,
                    data["12"].visitors,
                ]

                this.$refs.visitorsChart.updateOptions({
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    }
                }, true, true, true);
                
                this.$refs.visitorsChart.updateSeries([{
                    data: visitors,
                }], false, true);
            }

            if (chartType == 'daily') {
                let data = await this.getVisitorData('daily')
                
                let visitors = []
                let categories = []

                data.forEach((elem)=>{
                    visitors.push(elem.visitors)
                    categories.push(elem.day)
                })

                this.$refs.visitorsChart.updateOptions({
                    xaxis: {
                        categories: categories
                    }
                }, true, true, true);

                this.$refs.visitorsChart.updateSeries([{
                    data: visitors,
                }], false, true);

            }

        },
        async getVisitorData(dataType){
            const data =  await adminDashboardService.getVisitorsData(dataType)
            .then((res)=>{
                if(res.type === 'success'){
                    return res.data
                }else if(res.type === 'error'){
                    
                }
            })

            return data
        }
    },
    watch: {
        currentDataSet(new_currentDataSet, old_currentDataSet) {
            if(new_currentDataSet == 'daily'){
                
                this.prepareChart('daily')

                this.chartTitle = 'Daily Visitiors'
            }else if(new_currentDataSet == 'monthly'){
                
                this.prepareChart('monthly')
                
                this.chartTitle = 'Monthly Visitiors'
            }
            console.log(new_currentDataSet)
        }
    },
    mounted(){
        this.prepareChart('monthly')
        
    }

}
</script>

<style>

</style>