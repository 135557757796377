<template>
    <div>
        <div  class="item" v-if="eventToBeEdited && editEvent">
            <div class="p-6 bg-white border border-gray-200 shadow-md">                
                <addAndEditEventFormVue 
                @done-with-editing-events="relayEventToReachParent_one" 
                @go-back-to-viz="editEvent = false" 
                :event="eventToBeEdited.event" 
                :timelineDetails="timelineDetails" 
                :eventIndex="eventToBeEdited.index" 
                :parent="'viz'"/>
            </div>
        </div>
        <div class="item" v-if="item && !editEvent">
            <div class="p-6 bg-white border border-gray-200 shadow-md">
                <div>
                    <div class="float-right">
                        <timelineEventTagsControllerVue :event="eventToBeEdited.event" :eventIndex="eventToBeEdited.index"  @save-event="relayEventToReachParent_one"/>              
                    </div>
                    <div class="clear-both"></div>
                </div>
                <span>
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{ (item.event_category) ? item.event_category : ''}}</h5>
                </span>
                <p class="mb-3 font-normal text-gray-700 ">{{(item.event_description)?item.event_description:''}}</p>
                <div v-if="item.event_documents" class="mb-3 font-normal ">
                    <h4 class="font-medium text-gray-500">Documents</h4>
                    <ul class="list-disc list-inside">
                        <li v-for="(doc, index) in item.event_documents" :key="index" class="hover:text-gray-500 hover:cursor-pointer">{{ doc.event_document_name }}</li>
                    </ul>
                </div>
    
                <div class="flex my-4 space-x-6 md:mt-6 w-full text-gray-900">
                    <div class="basis-1/2">
                        <p>
                            <span class="mr-2"><i class="text-gray-500 far fa-calendar-alt"></i></span>
                            <span>{{(item.event_happen)?item.event_happen:''}}</span>
                        </p>
                    </div>
                    <div v-if="item.event_documents" class="basis-1/2">
                        <h4 class="font-medium text-gray-500">Employers</h4>
                        <p>
                            <ul class="list-disc list-inside">
                                <li v-for="(employer, index) in item.event_employer" :key="index" class="mr-1">
                                    {{ (employer.employer_name)? employer.employer_name : '' }}
                                    {{ (employer.employer_job)? `(${employer.employer_job})` : '' }} ;                                
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
    
                <button @click="editEvent = true" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-customb-500 rounded-lg hover:bg-gray-800 cursor-no-drop">
                    Edit
                    <svg aria-hidden="true" class="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>
    
        </div>
    </div>
</template>

<script>
import addAndEditEventFormVue from './addAndEditEventForm.vue';
import timelineEventTagsControllerVue from './timelineEventTagsController.vue';
var isEqual = require('lodash.isequal');



export default {
    props: {
        item: Object,
        events: Array,
        timelineDetails: Object
    },
    data(){
        return {
            eventToBeEdited: null,
            editEvent: false
        }
    },
    components: {
        addAndEditEventFormVue,
        timelineEventTagsControllerVue
    },
    methods: {
        relayEventToReachParent_one(eventEditedObj){
           this.$emit('relay-events-one', eventEditedObj)
        }
    },
    mounted(){
        if(this.item && this.events){
            const { event_happen, admin_note, event_category, event_method, event_employer, event_description, event_documents, tags  } = this.item

            let selectedEvents = this.events.filter(elem => {
                return elem.event_happen === event_happen
            })

            const equivSelectedElemInAllEvent = {
                index: null,
                event: null
            }
            

            selectedEvents.forEach(selectedEvent =>{
                delete selectedEvent.id
                delete selectedEvent.start

                for (let index = 0; index < this.events.length; index++) {
                    const element = this.events[index];
                    
                    if(isEqual(element, selectedEvent)){
                        equivSelectedElemInAllEvent.index = index
                        equivSelectedElemInAllEvent.event = element
                    }
                }

            })
            
            if(equivSelectedElemInAllEvent){
                this.eventToBeEdited = equivSelectedElemInAllEvent
            }
            
        }
    }
}
</script>

<style scoped>
.item{
    max-width: 600px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}

.item::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.item::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.item::-webkit-scrollbar-thumb
{
	background-color: #777;
}
</style>