import { authHeader } from '@/helper';
import { URL } from '@/helper';
import axios from 'axios'
import router from '@/router';
import userModule from '@/store/modules/user.module';


export const authServices = {
    login,
    logout,
    user 
    // register
};

async function login(email, password) {
    try {        
        return await axios.post(URL+'/api/login', {
            email: email,
            password: password
          })
            .then(function (response) {
                if (response.data.status == "error") {   
                    return response.data.message                           
                }else{
                    var res = {
                        type: 'success',
                        user: null 
                    }
                    let user = response.data.user
                    if (user) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('user', JSON.stringify(user)); 
                        res.user = user               
                        return res
                    }
                }
            })
    } catch (error) {
        var res = {
            type: 'error',
            location: 'login', 
            message: null 
        }

        res.message = 'Sorry, we are experiencing some chanllenges. Please try again or contact support'
        return res
    }

}

async function logout() {
    // remove user from local storage to log user out
    resetUser()
    await router.push({name: 'home'})

}

function user(){
    return userModule.state.user
}

function resetUser(){
    localStorage.removeItem('user')
}

